<template>
  <div class="footer-box" :class="{'mobile':isMobileBol}">
    <div class="footer_image">
      <div v-for="item in imgList" :key="item.id" @click="handleUrl(item)">
        <img :style="{'width':item.width}" :src="item.src" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  data () {
    return {
      imgList: [{
        id: 1,
        src: require('../assets/certik-logo-white.png'),
        url: 'https://www.certik.org/projects/cppc',
        width: "60px"
      }, {
        id: 2,
        src: require('../assets/TG0820-1.png'),
        url: 'https://titanservice.cn/Charm888',
        width: "50px"
      }, {
        id: 3,
        src: require('../assets/Email0820-1.png'),
        url: 'mailto:info@powerswap.org',
        width: "50px"
      }],
    }
  },
  methods: {
    handleUrl (item) {
      window.open(item.url)
    }
  },
  computed: {
    ...mapState(['isMobileBol'])
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.footer-box {
  color: #c3c5cb;
  display: flex;
  padding-left: 10%;
  margin-bottom: 10px;
  .footer_image {
    display: flex;
    align-items: baseline;
    > div {
      margin-right: 16px;
      img {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &.mobile {
    padding: 0;
    justify-content: center;
  }
}
</style>
