import store from './store'
import kovanNet from './netConfig/kovanNet.js'
import hecoNet from './netConfig/hecoNet.js'
import bscNet from './netConfig/bscNet.js'

const pricefeedServer = process.env.VUE_APP_PRICEFEED_SERVE // 喂价服务

// 不同网络下（heco,kovan,bsc），各项配置，chainId为唯一标识
const networkConfigList = [hecoNet, kovanNet, bscNet]

const getNetworkConfig = async () => { // 根据网络id获取对应配置
  // console.log(store.state.currentNetworkConfig, 'store.state.currentNetworkConfig')
  if (store.state.currentNetworkConfig) { // 先从vuex中获取
    return store.state.currentNetworkConfig
  } else { // vuex还未存储（一般是初始进入页面，未完全初始化），通过getCurrentChainId获取
    const chainId = await getCurrentChainId()
    return networkConfigList.find((item) => { return item.chainId === parseInt(chainId, 16) })// 获取对应的网络配置
  }
}
const getCurrentChainId = () => { // 通过web3 获取当前连接的链Id
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({
        method: 'eth_chainId'
      })
      .then(res => {
        resolve(res)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

export { getNetworkConfig, pricefeedServer }
