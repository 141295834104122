// // 考虑到项目本身不大，也为了方便开发，这里映射的key值就直接使用原中文值，不再另外定义。
export default {
  交易: '交易', // 1.顶部菜单
  持仓: '持仓',
  行情: '行情',
  流动性: '流动性',
  质押: '质押',
  分红币: '分红币',
  切换到旧版: '切换到旧版',
  聪期权: '聪期权',
  操作教程: '操作教程',

  暂无数据: '暂无数据',

  加载中: '加载中',
  错误网络: '错误网络',
  请选择钱包类型: '请选择钱包类型',
  连接: '连接',
  请先安装插件: '请先安装插件',
  取消: '取消',
  登出: '登出',
  我的账户: '我的账户',

  牛证: '牛证', // 2.交易
  熊证: '熊证',
  标的资产: '标的资产',
  当前价格: '当前价格',
  结算币: '结算币',
  余额:'余额',
  钱包余额: '钱包余额',
  杠杆倍数: '杠杆倍数',
  开仓: '开仓',
  平仓: '平仓',
  交易成功: '交易成功',
  在HECOINFO上查看: '在HECOINFO上查看',
  在KOVANSCAN上查看: '在KOVANSCAN上查看',
  在BSCSCAN上查看: '在BSCSCAN上查看',

  '正在为您开仓，请耐心等待……': '正在为您开仓，请耐心等待……',
  请先连接您的钱包: 'Please connect your wallet first',
  解锁钱包: ' Unlock wallet',

  您的持仓: '您的持仓', // 3.持仓
  同步数据: '同步数据',
  '当本地持仓数据与链上数据不一致时，请点击此按钮进行同步': '当本地持仓数据与链上数据不一致时，请点击此按钮进行同步',
  // 币种: '币种',
  类型: '类型',
  详情: '详情',
  权证数量: '权证数量',
  权证价格: '权证价格',
  开仓数额: '开仓数额',
  平仓比例: '平仓比例',
  平仓数量: '平仓数量',
  开仓成本: '开仓成本',
  持仓数额: '持仓数额',
  标的现价: '标的现价',
  开仓均价: '开仓均价',
  收益率: '收益率',
  rebase价格: 'rebase价格',
  '价格在0~2之间变化': '价格在0~2之间变化',
  权证价格重置为初始值1: '权证价格重置为初始值1',
  最大: '最大',
  收起筛选: '收起筛选',
  开始筛选: '开始筛选',
  开仓时间: '开仓时间',

  历史价格: '历史价格 ', // 4.行情
  标的资产价格: '标的资产价格',
  操作: '操作 ',
  价格: '价格 ',
  最近1小时: '最近1小时 ',
  最近24小时: '最近24小时 ',
  最近3天: '最近3天 ',
  全部: '全部 ',
  时间: '时间 ',
  交易所: '交易所',
  市值: '市值',

  '提供流动性，挖矿平台币': '提供流动性，挖矿平台币', // 5.流动性
  提供流动性: '提供流动性', // 5.流动性
  挖矿平台币: '挖矿平台币', // 5.流动性
  持有流动性数量: '持有流动性数量',
  预估对应结算币数量: '预估对应结算币数量',
  移除流动性: '移除流动性',
  移除比例: '移除比例',
  预计获得流动性份额: '预计获得流动性份额',
  批准: '批准',
  确定: '确定',
  结算币总量: '结算币总量',

  收益: '收益', // 6.质押
  可获取: '可获取',
  抵押: '抵押',
  取消抵押: '取消抵押',
  获取: '获取',
  质押2: '质押',
  持有数量: '持有数量',
  未质押: '未质押',
  已质押: '已质押',
  天: '天',
  取消质押: '取消质押',
  先添加流动性: '先添加流动性',

  提供平台币挖矿: '提供平台币挖矿', // 7.二级抵押
  抵押CPPC: '抵押CPPC',
  分红币数量: '分红币数量',
  兑换: '兑换',
  销毁: '销毁',
  分红币占比: '分红币占比',
  池子可获取利润: '池子可获取利润',

  邮箱: '邮箱', // 其他
  需先提供流动性再质押: '需先提供流动性再质押',
  牛熊证:'牛熊证',
  开仓数量:'开仓数量',
  标的物:'标的物',
  做多:'做多',
  做空:'做空',
  // 挖矿
  质押挖矿:'质押挖矿',
  质押APR:'质押APR',
  质押TVL:'质押TVL',
  质押收益: '质押收益',
  质押获取:'质押获取',
  添加质押:'添加质押',
  移除质押:'移除质押',
  添加流动性:'添加流动性',
  总开仓量:'总开仓量',
  平台分红:'平台分红',
  流动性总量:'流动性总量',
  持有流动性代币:'持有流动性代币',
  流动性占比:'流动性占比',
  分红币总量:'分红币总量',
  可获取利润:'可获取利润',
  将CHARM置换成分红币:'将CHARM置换成分红币',
  销毁分红币获取分红:'销毁分红币获取分红'
}
