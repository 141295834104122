
// heco网络
const hecoNet =   {
  chainId: 128,
  netUrl: 'https://hecoinfo.com',
  name: 'Huobi ECO Chain Mainnet',
  shortName: 'heco',
  chain: 'Heco',
  unit: 'HT',
  networkName: 'Heco',
  tradeSuccessTips: '在HECOINFO上查看',
  tradeType: ['BTC', 'UNI', 'ETH'],
  liquidityTokenAddress: { //  结算币  流动性的address--(方便前端通过币种name,直接拿liquidityTokenAddress)
    HT: '0xCBC38D49d6FA659b7388a36F23994CeDfed413c1',
    USDT: '0x1121B67eBE5304679f5b07D9f4c5DD4753bF52ED',
    CHARM: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA',
    'HT/CHARM': '0x37a194f5632e5426A254dd7D508DacACDac22BA3', // 从powerswap过来的质押币 就是对应的address
    'USDT/CHARM': '0x889231685bCCF870555B5Fe11F0c1A405e37A140'
  },
  tradeTokenList: [// 当前网络下 标的物类型
    {
      Bytes32: '0x4254430000000000000000000000000000000000000000000000000000000000',
      name: 'BTC'
    },
    {
      Bytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      name: 'ETH'
    },
    {
      Bytes32: '0x554e490000000000000000000000000000000000000000000000000000000000',
      name: 'UNI'
    }
  ],
  settleTokenList: [// 结算币种类
    {
      address: '0xa71edc38d189767582c38a3145b5873052c3e47a',
      liquidityTokenAddr: '0x1121B67eBE5304679f5b07D9f4c5DD4753bF52ED',
      Bytes32: '0x5553445400000000000000000000000000000000000000000000000000000000',
      name: 'USDT',
      logoName: 'USDT.png'// 仅前端逻辑用
    },
    {
      address: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
      liquidityTokenAddr: '0xCBC38D49d6FA659b7388a36F23994CeDfed413c1',
      Bytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      name: 'HT',
      logoName: 'HT.png'//

    },
    {
      address: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA',
      liquidityTokenAddr: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA',
      Bytes32: '0x434841524d000000000000000000000000000000000000000000000000000000',
      name: 'CHARM',
      logoName: 'CHARM.png'//

    }
  ],
  powerswapSettleTokenList: [// 从powerswap过来的质押币
    {
      name: 'HT/CHARM',
      Bytes32: '0x48542f434841524d000000000000000000000000000000000000000000000000',
      address: '0x37a194f5632e5426A254dd7D508DacACDac22BA3',
      liquidityTokenAddr: '0x37a194f5632e5426A254dd7D508DacACDac22BA3',
      logoName: 'HT-CHARM.png', //
      isPowerswap: true
    },
    {
      name: 'USDT/CHARM',
      Bytes32: '0x555344542f434841524d00000000000000000000000000000000000000000000',
      address: '0x889231685bCCF870555B5Fe11F0c1A405e37A140',
      liquidityTokenAddr: '0x889231685bCCF870555B5Fe11F0c1A405e37A140',
      logoName: 'USDT-CHARM.png', //
      isPowerswap: true
    }
  ],
  tradeLiquiditySettleTokenList: [// 能开仓的流动币类型
    {
      address: '0x1121B67eBE5304679f5b07D9f4c5DD4753bF52ED',
      liquidityTokenAddr: '0x1121B67eBE5304679f5b07D9f4c5DD4753bF52ED',
      Bytes32: '0x6c55534454000000000000000000000000000000000000000000000000000000',
      settleBytes32: '0x5553445400000000000000000000000000000000000000000000000000000000',
      isLiquidity: true,
      name: 'lUSDT'
    },
    {
      address: '0xCBC38D49d6FA659b7388a36F23994CeDfed413c1',
      liquidityTokenAddr: '0xCBC38D49d6FA659b7388a36F23994CeDfed413c1',
      Bytes32: '0x6c48540000000000000000000000000000000000000000000000000000000000',
      settleBytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      isLiquidity: true,
      name: 'lHT'
    }
  ],
  dividendSettleTokenList: [// 分红币币种类
    {
      address: '0x030811031BBfE1949Aa7B181Ff7559cb8fC80D23',
      settleTokenAddr: '0xa71edc38d189767582c38a3145b5873052c3e47a',
      Bytes32: '0x6455534454000000000000000000000000000000000000000000000000000000',
      name: 'dUSDT'
    },
    {
      address: '0xE88ee999894c2fFEdBe7b640F3C71D633dC1ea76',
      settleTokenAddr: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
      Bytes32: '0x6448540000000000000000000000000000000000000000000000000000000000',
      name: 'dETH'
    }
  ],
  addressBook: { // 各种 address(实例化各类abi合约)
    SystemSettingsAddress: '0xcB8331f9b0F8639281119822c8Ce7AC67EE03447',
    cbbcRouterAddress: '0x7062E005B73a55a52449D29101249501Fe23Ca12',
    orchestratorAddress: '0x6D55D2C55f00385181365C6Ea2774903140AEEE4',
    marketOracleAddress: '0x5Fb001E9cF6f66024a8F61d02593A64074739558',

    wethAddress: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f', // 注：就是结算币ETH的address（heco下就是HT的address）
    addressResolver: '0x848620ba74a00833a51C40973cb659AA64d190D3',

    cppcChefAddress: '0x868c28aee9C3A2Ddec148Cb15A146677b0fb4776',
    IssuerForCbbcTokenAddress: '0xA85Ff5cB2c64f39224C5f6d240AD822D2893d230'
  },
  cbbcType: [// 各种类型权证信息
    {
      name: '1X BTC HT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6331425443485400000000000000000000000000000000000000000000000000',
      address: '0x5992188d99F0ae1A340b69C0a451db1A541101AF',
      leverage: 1,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '1X Inverse BTC HT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6331494254434854000000000000000000000000000000000000000000000000',
      address: '0x95de8d2A753146d94cDBbF74ff89c7b5d94765b9',
      leverage: 1,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '2X BTC HT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6332425443485400000000000000000000000000000000000000000000000000',
      address: '0x80E9e9e44C332FD613dE026Fd3EbCBdA7a90bc8D',
      leverage: 2,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '2X Inverse BTC HT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6332494254434854000000000000000000000000000000000000000000000000',
      address: '0x179272BDcACca9E2274E294Fa9D9dC2bb898e1D5',
      leverage: 2,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '3X BTC HT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6333425443485400000000000000000000000000000000000000000000000000',
      address: '0x4429247b7B937969c3C89f1166E907cC9B3079ae',
      leverage: 3,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '3X Inverse BTC HT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6333494254434854000000000000000000000000000000000000000000000000',
      address: '0x4358A4576C4BebdA181819d2B5d6BB0290F16C04',
      leverage: 3,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '5X BTC HT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6335425443485400000000000000000000000000000000000000000000000000',
      address: '0xA507CBBEb3b120b31d7A55072d5dF79208c89605',
      leverage: 5,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '5X Inverse BTC HT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'HT',
      Bytes32: '0x6335494254434854000000000000000000000000000000000000000000000000',
      address: '0x37Cb4890C774E2AA494A7d6409691016bf0e6463',
      leverage: 5,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },

    {
      name: '1X ETH HT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6331455448485400000000000000000000000000000000000000000000000000',
      address: '0x467493A46bD7f043BfC9164c0ea27954627B59bC',
      leverage: 1,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '1X Inverse ETH HT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6331494554484854000000000000000000000000000000000000000000000000',
      address: '0x404209271b3358880361AF3C710Cc928e4c07F08',
      leverage: 1,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '2X ETH HT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6332455448485400000000000000000000000000000000000000000000000000',
      address: '0x6984251b7f030e0d359df798455f1eCA45bdB541',
      leverage: 2,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '2X Inverse ETH HT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6332494554484854000000000000000000000000000000000000000000000000',
      address: '0x89cB66Ba14Eb496A030918C696210e47785D2543',
      leverage: 2,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '3X ETH HT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6333455448485400000000000000000000000000000000000000000000000000',
      address: '0x773d974F864c7c50a93A86bd23431B2F69be3140',
      leverage: 3,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '3X Inverse ETH HT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6333494554484854000000000000000000000000000000000000000000000000',
      address: '0xfDfBE3F0435E7754cA9D53e39a308d010d14e87E',
      leverage: 3,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '5X ETH HT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6335455448485400000000000000000000000000000000000000000000000000',
      address: '0xB6099572624a37dE4265abEc37054bc46D81dC46',
      leverage: 5,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '5X Inverse ETH HT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'HT',
      Bytes32: '0x6335494554484854000000000000000000000000000000000000000000000000',
      address: '0xab7D282460CE6478Bdf890222Ce09471371803c9',
      leverage: 5,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    }, {
      name: '1X UNI HT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x6331554e49485400000000000000000000000000000000000000000000000000',
      address: '0x983287400D52fe976CC0E8DdE3C5Ed87F5738691',
      leverage: 1,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '1X Inverse UNI HT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x633149554e494854000000000000000000000000000000000000000000000000',
      address: '0xb14A5D6A5FBcA39f05DdA73120DF5a91c3396184',
      leverage: 1,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    }, {
      name: '2X UNI HT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x6332554e49485400000000000000000000000000000000000000000000000000',
      address: '0xEa6D7B72c696B8c486934a3fF094E4fFe304DBeD',
      leverage: 2,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '2X Inverse UNI HT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x633249554e494854000000000000000000000000000000000000000000000000',
      address: '0xD5A24e6756C2bC2a2280307b93beb1BbD267ea22',
      leverage: 2,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    }, {
      name: '3X UNI HT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x6333554e49485400000000000000000000000000000000000000000000000000',
      address: '0x5B5BEd7706FaC78c37685252f38c6e951A9E7AC5',
      leverage: 3,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '3X Inverse UNI HT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x633349554e494854000000000000000000000000000000000000000000000000',
      address: '0x7ADC302761ABf6E6f3934684c358C83a3ADdD60E',
      leverage: 3,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    }, {
      name: '5X UNI HT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x6335554e49485400000000000000000000000000000000000000000000000000',
      address: '0xB6F4764D865785f2EEbdE0A2bC4B4951f1C3874b',
      leverage: 5,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    },
    {
      name: '5X Inverse UNI HT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'HT',
      Bytes32: '0x633549554e494854000000000000000000000000000000000000000000000000',
      address: '0xC1FDF4D66F799dF8F6E94051e19429C079F58eB5',
      leverage: 5,
      settleToken: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f'
    }, {
      name: '1X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331425443555344540000000000000000000000000000000000000000000000',
      address: '0xAD1C6AC35051eE6Ac913091e820FC5ED48BC2DF3',
      leverage: 1,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '1X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331494254435553445400000000000000000000000000000000000000000000',
      address: '0x0BAd6577bCc76DdbA491Ab51bE7D0F42CaB65676',
      leverage: 1,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '2X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6332425443555344540000000000000000000000000000000000000000000000',
      address: '0x96Df48217189B736aF3e8616fF57A4CCd89Ab383',
      leverage: 2,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '2X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6332494254435553445400000000000000000000000000000000000000000000',
      address: '0xcF0DbbB98f4a5778fF6355a972B0D1b2914e61bC',
      leverage: 2,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '5X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6335425443555344540000000000000000000000000000000000000000000000',
      address: '0x634B97ead82b77e7023Ff68Bc60bC9244877B797',
      leverage: 5,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '5X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6335494254435553445400000000000000000000000000000000000000000000',
      address: '0x1Bf9980477FD2A53E928350Ad1c7FbF2c9356ef7',
      leverage: 5,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '10X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331304254435553445400000000000000000000000000000000000000000000',
      address: '0xA952398991905746df18135706Cd96C1e68e4170',
      leverage: 10,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '10X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331304942544355534454000000000000000000000000000000000000000000',
      address: '0x029e4fa9fdF8E838bE8Be8C5f2E8752616F6C980',
      leverage: 10,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '1X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331455448555344540000000000000000000000000000000000000000000000',
      address: '0x9b698ba77e386823F63a64acA5edb2EFdaA629BA',
      leverage: 1,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '1X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331494554485553445400000000000000000000000000000000000000000000',
      address: '0x25448761cD3dF71925a66E8B196f347A5E641bc0',
      leverage: 1,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '2X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6332455448555344540000000000000000000000000000000000000000000000',
      address: '0x6D71de82C29b6FE92744A9b7AAD207e83caC993e',
      leverage: 2,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '2X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6332494554485553445400000000000000000000000000000000000000000000',
      address: '0x2c7cDC1C16E9154C26CF083f387d0361bA4Ea845',
      leverage: 2,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '5X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6335455448555344540000000000000000000000000000000000000000000000',
      address: '0x1a1b141C1dE3CfBC621266bB9D986c5d99996a33',
      leverage: 5,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '5X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6335494554485553445400000000000000000000000000000000000000000000',
      address: '0xAb3aA39BD356b70fB3365c3A10040Dc4624D627B',
      leverage: 5,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '10X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331304554485553445400000000000000000000000000000000000000000000',
      address: '0x950612d518812C7014D47Cefcf7E0A7f885C8698',
      leverage: 10,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '10X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331304945544855534454000000000000000000000000000000000000000000',
      address: '0x85afF48Ada09a753891D4774C31019Ba4C45f61B',
      leverage: 10,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '1X UNI USDT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x6331554e49555344540000000000000000000000000000000000000000000000',
      address: '0xD9328413CF700611eeC95218729BaDE95e1c2034',
      leverage: 1,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '1X Inverse UNI USDT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x633149554e495553445400000000000000000000000000000000000000000000',
      address: '0x76F720b34533AAAe62b5C06b0d327cE68C1EC65E',
      leverage: 1,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '2X UNI USDT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x6332554e49555344540000000000000000000000000000000000000000000000',
      address: '0x09c62478F1ed3771176770c1F1fc71c9c1B00cD0',
      leverage: 2,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '2X Inverse UNI USDT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x633249554e495553445400000000000000000000000000000000000000000000',
      address: '0xedC43a7A03735Fa998C1e9bFC3F92d068Aa7fa36',
      leverage: 2,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '5X UNI USDT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x6335554e49555344540000000000000000000000000000000000000000000000',
      address: '0x8e0bdc1286c87210490856232bc00aACb643aD47',
      leverage: 5,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '5X Inverse UNI USDT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x633549554e495553445400000000000000000000000000000000000000000000',
      address: '0x329e5616AEd6F07bDD14eD102242ffD7f69Fc15d',
      leverage: 5,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    }, {
      name: '10X UNI USDT',
      type: '1',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x633130554e495553445400000000000000000000000000000000000000000000',
      address: '0x42dCC817C8F74fC07Ee41fbF737D8689895Fd10e',
      leverage: 10,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '10X Inverse UNI USDT',
      type: '0',
      tradeType: 'UNI',
      settleType: 'USDT',
      Bytes32: '0x63313049554e4955534454000000000000000000000000000000000000000000',
      address: '0xCe16b536b31f2E7d47FB4356145B8808f6F6Ee67',
      leverage: 10,
      settleToken: '0xa71edc38d189767582c38a3145b5873052c3e47a'
    },
    {
      name: '1X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x6331425443434841524d00000000000000000000000000000000000000000000',
      address: '0x19823F528AbA50Fca14e51c482a245b9643343Eb',
      leverage: 1,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '1X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633149425443434841524d000000000000000000000000000000000000000000',
      address: '0xdBc89c08b6c978eFB93Ff7cB25Ad926Ad2e51567',
      leverage: 1,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '5X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x6335425443434841524d00000000000000000000000000000000000000000000',
      address: '0x31fbe0bF91F950f982Ef0bC7ef1120c12685b766',
      leverage: 5,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '5X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633549425443434841524d000000000000000000000000000000000000000000',
      address: '0x70E87FAb66bF84f07e9fd53e54067F088e85D02a',
      leverage: 5,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '10X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
      address: '0x16F2977d1ee82b451F91dc63115DdBDFe3fd5263',
      leverage: 10,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '10X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
      address: '0x466c1A4044261c000F759fFF73a7d595B7185AcE',
      leverage: 10,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '20X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
      address: '0x789b793126d352EACF7d91AbE47DC9210c98548C',
      leverage: 20,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '20X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
      address: '0x77b2267443D0Fc9748841192eB51a3AD4c790B83',
      leverage: 20,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '1X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x6331455448434841524d00000000000000000000000000000000000000000000',
      address: '0x2A09db1439396296ed09Aa475db4c35e7b8CA4d1',
      leverage: 1,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '1X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633149455448434841524d000000000000000000000000000000000000000000',
      address: '0xCB5df3DE64e84fa990E72006c7c8097F567CcF7B',
      leverage: 1,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '5X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x6335455448434841524d00000000000000000000000000000000000000000000',
      address: '0x05b82dbF4BDCd32145B0e3e2c92A365EF41E3881',
      leverage: 5,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '5X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633549455448434841524d000000000000000000000000000000000000000000',
      address: '0xBBA1df21e963C373875456B9a2b8a389C16A7099',
      leverage: 5,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '10X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
      address: '0xdB1233581d2376EC0335A9FF8655B1893594FF1d',
      leverage: 10,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '10X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
      address: '0x83b0ACb260C95556120E36183D2aFa98ECE5bBDC',
      leverage: 10,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '20X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
      address: '0xB5D53CDe17faAa0CAFE1191A63B07A72C33B817c',
      leverage: 20,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '20X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
      address: '0x37585a03C2bA393dE60c64c380e3f00AEeF0DB6e',
      leverage: 20,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '1X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x6331554e49434841524d00000000000000000000000000000000000000000000',
      address: '0x78c060dcf057873F34cd5179e4Ac0a6A3164BDEA',
      leverage: 1,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '1X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633149554e49434841524d000000000000000000000000000000000000000000',
      address: '0x36069FEd8A355Ed7cC0D04AEbcA34ecAed6b0a99',
      leverage: 1,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '5X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x6335554e49434841524d00000000000000000000000000000000000000000000',
      address: '0x47510D4a4C00451505d15C8cBBcCD0464d05dFAf',
      leverage: 5,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '5X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633549554e49434841524d000000000000000000000000000000000000000000',
      address: '0x2fc5B31c477C1d8C967F1b3f8B7397607C6BED27',
      leverage: 5,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '10X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633130554e49434841524d000000000000000000000000000000000000000000',
      address: '0x33f49BEB7e19622fbf297cEEF40b9B312da61515',
      leverage: 10,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '10X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x63313049554e49434841524d0000000000000000000000000000000000000000',
      address: '0x03679227f3cF96B86D5Ec676F6b2E5d0ef8d40A0',
      leverage: 10,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '20X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633230554e49434841524d000000000000000000000000000000000000000000',
      address: '0x924E695a6FDECF678Fa1Cc7d1B485490409DD3B3',
      leverage: 20,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    },
    {
      name: '20X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x63323049554e49434841524d0000000000000000000000000000000000000000',
      address: '0x86c6EEaBb46ed0f7F3317dC914C62e93Cd113EA3',
      leverage: 20,
      settleToken: '0x69F2cfd9e2103C7271abF407D7340E750a4251DA'
    }

  ]
}
export default hecoNet;