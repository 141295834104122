<template>
  <div class="layout-box">
    <!-- 头部菜单 -->
    <v-header></v-header>
    <!-- 主内容区域 -->
    <div class="center-container">
      <router-view />
    </div>
    <!-- 底部 -->
    <Footer v-if="isMobileBol"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import vHeader from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { mapState } from 'vuex'

export default {
  name: 'Layout',
  data: () => ({

  }),
  computed: {
    ...mapState(['isMobileBol'])
  },
  components: {
    vHeader, 
    Footer
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.layout-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  // .center-container {
  // padding-top: 140px;
  //  height: calc(100vh - 170px);
  // }
}
</style>
