import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './utils/vuetify'
import store from './store'

import moment from 'moment'// 时间格式化工具
import echarts from './echarts'// 按需引入 echarts

Vue.prototype.$echarts = echarts
Vue.prototype.$moment = moment
Vue.config.productionTip = false

//zhh todo for product it should be false
Vue.config.devtools = true
// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  vuetify,
  store,
  render: h => h(App),
  created() {
    Vue.prototype.$t = function (key) { // 用$t方法 简化 $vuetify.lang.t('$vuetify.key')
      return this.$vuetify.lang.t(`$vuetify.${key}`)
    }
  }
})
