import store from './store'
import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from 'web3'
import cbbcToken from './abis/CbbcToken.json'
import cbbcRouter from './abis/Router.json'
import liquidityToken from './abis/CbbcLiquidityToken.json'
import marketOracle from './abis/MarketOracle.json'
import cppcChefV2 from './abis/CppcChefV2.json'
import addressResolver from './abis/AddressResolver.json'
import issuerForCbbcToken from './abis/IssuerForCbbcToken.json'
import SystemSettings from './abis/SystemSettings.json'
import CbbcERC20 from './abis/CbbcERC20.json'
//zhh 20221208
import cbbcRouterV2 from './abis/RouterV2.json'
//end zhh

import axios from 'axios'
import { splitSignature } from '@ethersproject/bytes'

import WalletConnectProvider from '@walletconnect/web3-provider'
import { getNetworkConfig, pricefeedServer } from './chainNetConfig.js'

var web3 = new Web3(Web3.givenProvider)


// console.log(web3.currentProvider, 'web3')

// (async () => {
// console.log(res, 'resres')
// })()









/*>>>>>>>>>>> 根据当前连接的网络类型（链id），实例化abi合约 start <<<<<<<<<<<<<<*/

//实例化cbbcRouterInstance
async function getCbbcRouterInstance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(cbbcRouter.abi, config.addressBook.cbbcRouterAddress)
}

//zhh 20221208
async function getCbbcRouterV2Instance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(cbbcRouterV2.abi, config.addressBook.routerV2Address)
}
//end

//实例化marketOracleInstance
async function getMarketOracleInstance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(marketOracle.abi, config.addressBook.marketOracleAddress)
}

//根据liquidityTokenName，实例化liquidityTokenInstance
async function getLiquidityTokenInstance(liquidityTokenName) {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(liquidityToken.abi, config.liquidityTokenAddress[liquidityTokenName])
}

// 实例化cppcChefInstance
async function getCppcChefInstance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(cppcChefV2.abi, config.addressBook.cppcChefAddress)
}

// 实例化addressResolver
async function getResolverInstance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(addressResolver.abi, config.addressBook.addressResolver)
}

// 实例化issuerForCbbcToken
async function getIssuerForCbbcTokenInstance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(issuerForCbbcToken.abi, config.addressBook.IssuerForCbbcTokenAddress)
}

// 实例化SystemSettings
async function getSystemSettingsInstance() {
  const config = await getNetworkConfig()
  return new web3.eth.Contract(SystemSettings.abi, config.addressBook.SystemSettingsAddress)
}

/*>>>>>>>>>>> 根据当前连接的网络类型（链id），实例化abi合约 end <<<<<<<<<<<<<<*/


/*>>>>>>>>>>> 封装的方法（获取当前网络的配置信息等） start <<<<<<<<<<<<<<*/

// 根据liquidityTokenName,获取 LiquidityTokenAddress
async function getLiquidityTokenAddress(liquidityTokenName) {
  const config = await getNetworkConfig()
  return config.liquidityTokenAddress[liquidityTokenName]
}

/*
  * 获取所有标的物类型 （直接从前端配置获取，注意与链上的配置同步)
  * return: [{address: string, name: string}]
  */
async function getTradeTokenListByConfig() {
  const config = await getNetworkConfig()
  const tradeTokenList = config.tradeTokenList
  return tradeTokenList
}

/*
  * 获取所有结算币类型（直接从前端配置获取，注意与链上的配置同步)
  * return: [{address: string, name: string}]
  */
async function getSettleTokenListByConfig() {
  const config = await getNetworkConfig()
  const settleTokenList = config.settleTokenList
  return settleTokenList
}

/* 调用 pricefeed服务，获取标的物加密的price（签名）
  * 目前有['BTC', 'UNI', 'ETH', 'BNB']4种标的物，不同网络下通用
  * return data < Array >
  */
function getTradePrice() {
  return new Promise((resolve, reject) => {
    axios.get(`${pricefeedServer}`)
      .then(function (response) {
        if (response.data.code === 0) {
          resolve(response.data)
        }
      }).catch((error) => {
        reject(error)
      })
  })
}

/*根据Bytes32获取address （可考虑废弃，可直接从配置取）
* return address  <string >
*/
async function getAddressByBytes32(Bytes32) {
  const resolverInstance = await getResolverInstance()
  const addr = await resolverInstance.methods.getAddress(Bytes32).call()
  return addr
}



function getDeadline() {
  return (Math.floor(Date.now() / 1000) + 1800).toString()
}

function toWei(amount) {
  return window.BigInt(Math.floor(amount * Math.pow(10, 18))).toString()
}

function toEth(amount) {
  if (amount == null) {
    console.error('null value is passed into function')
    return '0'
  }
  amount = window.BigInt(amount).toString()
  if (amount.length < 15) {
    return '0'
  } else {
    amount = amount.substring(0, amount.length - 14)
  }
  return (amount / 10000).toFixed(4).toString()
}

// argument: number
// return: string format of 4 decimal number round down
function to4DecimalString(number) {
  number = number.toString()
  const i = number.indexOf('.')
  const r = number.substring(0, i + 5)
  if (r === 0) { return '0' }
  return String((parseFloat(r)).toFixed(4))
}

/*>>>>>>>>>>> 封装的方法（获取当前网络的配置信息等） end <<<<<<<<<<<<<<*/





//zhh add parametr: settleTokenName
// 线下签名 （开仓，平仓）
async function signature(settleTokenName, name, nonce, tokenAddress, amount, ownerAddress, callback, isTowei = true) {
  const deadline = getDeadline()
  const config = await getNetworkConfig()
  const routeV2Tokens = ["CAKE", "BUSD"]
  const routeAddr = routeV2Tokens.includes(settleTokenName) ? config.addressBook.routerV2Address : config.addressBook.cbbcRouterAddress
  const msgParams = JSON.stringify({
    domain: {
      chainId: config.chainId,
      name: name,
      verifyingContract: tokenAddress,
      version: '1'
    },

    message: {
      owner: ownerAddress,
      spender: routeAddr,
      value: isTowei ? toWei(amount) : amount, // 销毁分红币，不用toWei，其他需要
      nonce: nonce,
      deadline: deadline
    },
    primaryType: 'Permit',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' }
      ],
      Permit: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' }
      ]
    }
  })

  web3.currentProvider.sendAsync(
    { method: 'eth_signTypedData_v4', params: [ownerAddress, msgParams], from: ownerAddress },
    function (err, result) {
      if (err != null) {
        callback(err, '', '')
      }
      const permitData = splitSignature(result.result)
      callback(err, permitData, deadline)
    })
}







/*>>>>>>>>>>> 交易，持仓页（开，平仓等相关操作）start  <<<<<<<<<<<<*/

/* 非流动性币开仓批准（流动性币开仓用 getLiquiditySignature 方法；也用于添加流动性的批准）
  *Bytes:结算币的Bytes32
  *amount:数量
  *accountAddress:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function approveToken(Bytes, amount, accountAddress, callback, onConfirm) {
  const address = await getAddressByBytes32(Bytes)// 根据Bytes32获取address
  // console.log(Bytes, address, 'Bytes,address')
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, address)
  const config = await getNetworkConfig()

  tokenInstance.methods.approve(config.addressBook.cbbcRouterAddress, toWei(amount)).send({ from: accountAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
  }).on('transactionHash', function () { // 此处并非真正approve成功
    // onConfirm(receipt)
  }).once('confirmation', function (receipt) { // 此处approve成功，返回回调（此时可以进行开仓等操作）
    onConfirm(receipt)
  })
}
//zhh
/*>>>>>>>>>>> 交易，持仓页（开，平仓等相关操作）start  <<<<<<<<<<<<*/

/* 非流动性币开仓批准（流动性币开仓用 getLiquiditySignature 方法；也用于添加流动性的批准）
  *Bytes:结算币的Bytes32
  *amount:数量
  *accountAddress:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function approveTokenRouteV2(currentSettle, amount, accountAddress, callback, onConfirm) {
  const address = await getAddressByBytes32(currentSettle.Bytes32)// 根据Bytes32获取address
  // console.log(Bytes, address, 'Bytes,address')
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, address)
  const config = await getNetworkConfig()
  // const routeV2Tokens = ["CAKE", "BUSD"]
  // currentSettle.name
  tokenInstance.methods.approve(config.addressBook.routerV2Address, toWei(amount)).send({ from: accountAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
  }).on('transactionHash', function () { // 此处并非真正approve成功
    // onConfirm(receipt)
  }).once('confirmation', function (receipt) { // 此处approve成功，返回回调（此时可以进行开仓等操作）
    onConfirm(receipt)
  })
}
//end

// 开仓分以下3种情况：

/* 1.常规结算币开仓（TKN4.USTD等）
  *settleBytes32:结算币的address
  *tradeBytes32:标的物的address
  *leverage:杠杆倍数
  *type:牛证 1，熊证 0
  *amount:数量
  *accountAddress:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function buyCbbc(settleBytes32, tradeBytes32, leverage, type, amount, accountAddress, callback, onConfirm) {
  const cbbcRouterInstance = await getCbbcRouterInstance()

  const config = await getNetworkConfig()
  const tradePriceInfo = await getTradePrice()

  if (tradePriceInfo.code === 0) {
    const currentradeType = config.tradeTokenList.find((i) => { return i.Bytes32 === tradeBytes32 }).name
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === currentradeType })
    cbbcRouterInstance.methods.buyCbbc([priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature], settleBytes32, tradeBytes32, leverage, type, toWei(amount), accountAddress, getDeadline())
      .send({ from: accountAddress }, async function (error, transactionHash) {
        callback(error, transactionHash)
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt, toEth(priceData.tradePrice))
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
  }
}

/* 2.流动性币开仓（lETL,lTKN4,CHARM等）
  *settleBytes32:结算币的address
  *tradeBytes32:标的物的address
  *leverage:杠杆倍数
  *type:牛证 1，熊证 0
  *amount:数量
  *accountAddress:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function buyCbbcUsingLiquidityTokenWithPermit(settleBytes32, tradeBytes32, leverage, type, amount, accountAddress, deadline, permit, callback, onConfirm) {
  console.log('before call buyCbbcUsingLiquidityTokenWithPermit')

  console.log('settleBytes32:' + settleBytes32)
  console.log('tradeBytes32:' + tradeBytes32)
  console.log('type:' + type)
  console.log('accountAddress:' + accountAddress)
  console.log('permit:' + permit)
  const cbbcRouterInstance = await getCbbcRouterInstance()

  const config = await getNetworkConfig()
  const tradePriceInfo = await getTradePrice()
  if (tradePriceInfo.code === 0) {
    const currentradeType = config.tradeTokenList.find((i) => { return i.Bytes32 === tradeBytes32 }).name
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === currentradeType })

    console.log('currentradeType:' + currentradeType)
    console.log('priceData:' + priceData)

    cbbcRouterInstance.methods.buyCbbcUsingLiquidityTokenWithPermit(
      [priceData.tradePrice, priceData.nonce, priceData.expireTimestamp,
      priceData.signature],
      settleBytes32, tradeBytes32, leverage, type, toWei(amount),
      accountAddress, deadline, [false, permit.v, permit.r, permit.s])
      .send({ from: accountAddress }, async function (error, transactionHash) {
        callback(error, transactionHash)
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt, toEth(priceData.tradePrice))
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
  }
}

/* 3.HT,BNB，ETH等结算币开仓
  *tradeBytes32 <string>:标的物的address
  *leverage  <string>:杠杆倍数
  *type  <number>:牛证 1，熊证 0
  *amount  <number>:数量
  *accountAddress  <string>:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function buyCbbcETH(tradeBytes32, leverage, type, amount, accountAddress, callback, onConfirm) {
  const cbbcRouterInstance = await getCbbcRouterInstance()
  const config = await getNetworkConfig()
  const tradePriceInfo = await getTradePrice()
  if (tradePriceInfo.code === 0) {
    const currentradeType = config.tradeTokenList.find((i) => { return i.Bytes32 === tradeBytes32 }).name
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === currentradeType })
    const data = cbbcRouterInstance.methods.buyCbbcETH([priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature], tradeBytes32, leverage, type, accountAddress, getDeadline()).encodeABI()
    web3.eth.sendTransaction({ to: config.addressBook.cbbcRouterAddress, from: accountAddress, data: data, value: toWei(amount) }, async function (error, transactionHash) {
      callback(error, transactionHash)
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt, toEth(priceData.tradePrice))
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })
  }
}


/* 平仓签名--批准（线下）
  *cbbcAddr <string>: 当前权证的address
  *amount  <number>:数量
  *ownerAddress  <string>:账户address
  *callback:FUNCTION
  */
async function sellSignature(settleTokenName, cbbcAddr, amount, ownerAddress, callback) {
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, cbbcAddr)
  const nonce = await tokenInstance.methods.nonces(ownerAddress).call()
  const name = await tokenInstance.methods.name().call()
  console.log('sellSignature, settleTokenName:' + settleTokenName)
  signature(settleTokenName, name, nonce, cbbcAddr, amount, ownerAddress, callback)
}

/* 平仓
  *cbbcAddr <string>: 当前权证的address
  *tradeType  <number>:标的物类型---BTC,UNI,ETH
  *amount  <number>:数量
  *ownerAddress  <string>:账户address
  *deadline:deadline
  *permit:permit
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function sellCbbcWithPermit(cbbcAddr, tradeType, amount, ownerAddress, deadline, permit, callback, onConfirm) {
  const cbbcInstance = new web3.eth.Contract(cbbcToken.abi, cbbcAddr)
  const leverage = await cbbcInstance.methods.leverage().call()
  const cbbcType = await cbbcInstance.methods.cbbcType().call()

  //zhh 20221217
  //const routeV2Tokens = ["CAKE", "MATIC", "MDX", "BUSD"]
  const routeV2Tokens = ["CAKE", "BUSD"]
  const charmByte32 = '0x434841524d000000000000000000000000000000000000000000000000000000'

  const cbbcRouterV2Instance = await getCbbcRouterV2Instance()
  //zhh end
  const cbbcRouterInstance = await getCbbcRouterInstance()
  const config = await getNetworkConfig()
  const wethAddress = config.addressBook.wethAddress

  const settleTokenAddr = config.cbbcType.find((i) => { return i.address === cbbcAddr }).settleToken
  const settleBytes32 = config.settleTokenList.find((i) => { return i.address === settleTokenAddr }).Bytes32
  const settleTokenName = config.settleTokenList.find((i) => { return i.address === settleTokenAddr }).name
  const curTradeToken = config.tradeTokenList.find((i) => { return i.name === tradeType })

  const tradePriceInfo = await getTradePrice()
  if (tradePriceInfo.code === 0) {
    console.log(tradePriceInfo, 'tradePriceInfo')
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === curTradeToken.name })
    // return
    if (settleTokenAddr.toUpperCase() === wethAddress.toUpperCase()) {
      cbbcRouterInstance.methods.sellCbbcETHWithPermit(
        [priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature],
        curTradeToken.Bytes32, leverage, cbbcType, toWei(amount), 0, ownerAddress, deadline,
        [false, permit.v, permit.r, permit.s]
      ).send({ from: ownerAddress }, async function (error, transactionHash) {
        callback(error, transactionHash)
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt)
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
    } else if (routeV2Tokens.includes(settleTokenName)) {  //zhh add "CAKE", "MATIC", "MDX", "BUSD" 20221210
      cbbcRouterV2Instance.methods.sellCbbcWithPermit(
        [priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature],
        settleBytes32,
        [charmByte32, curTradeToken.Bytes32, leverage, cbbcType],
        toWei(amount), 0, ownerAddress, deadline,
        [false, permit.v, permit.r, permit.s]
      ).send({ from: ownerAddress }, async function (error, transactionHash) {
        callback(error, transactionHash)
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt)
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
    }//zhh end
    else {
      cbbcRouterInstance.methods.sellCbbcWithPermit(
        [priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature],
        settleBytes32, curTradeToken.Bytes32, leverage, cbbcType, toWei(amount), 0, ownerAddress, deadline,
        [false, permit.v, permit.r, permit.s]
      ).send({ from: ownerAddress }, async function (error, transactionHash) {
        callback(error, transactionHash)
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt)
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
    }
  }
}

//zhh 20221209
/* 1.常规结算币开仓（TKN4.USTD等）
  *settleBytes32:charm结算币的address
  *tradeBytes32:标的物 byte32
  *leverage:杠杆倍数
  *type:牛证 1，熊证 0
  *amount:数量
  *accountAddress:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function buyCbbcRouteV2(settleBytes32, tradeBytes32, leverage, type, amount, accountAddress, callback, onConfirm) {
  const cbbcRouterV2Instance = await getCbbcRouterV2Instance()
  const charmByte32 = '0x434841524d000000000000000000000000000000000000000000000000000000'
  console.log("accountAddress:" + accountAddress)
  const config = await getNetworkConfig()
  const tradePriceInfo = await getTradePrice()

  if (tradePriceInfo.code === 0) {
    const currentradeType = config.tradeTokenList.find((i) => { return i.Bytes32 === tradeBytes32 }).name
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === currentradeType })
    cbbcRouterV2Instance.methods.buyCbbc(
      [priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature],
      settleBytes32,
      [charmByte32, tradeBytes32, leverage, type], //cTokenSettings
      toWei(amount), accountAddress, getDeadline())
      .send({ from: accountAddress }, async function (error, transactionHash) {
        callback(error, transactionHash)
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt, toEth(priceData.tradePrice))
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
  }
}

/* 平仓2 用routev2 暂没用上
  *cbbcAddr <string>: 当前charm权证的address //x
  *tradeType  <number>:标的物类型---BTC,UNI,ETH
  *amount  <number>:数量
  *ownerAddress  <string>:账户address
  *deadline:deadline
  *permit:permit
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function sellCbbcWithPermitRouteV2(cbbcAddr, tradeType, amount, ownerAddress, deadline, permit, callback, onConfirm) {

  const cbbcInstance = new web3.eth.Contract(cbbcToken.abi, cbbcAddr)
  const leverage = await cbbcInstance.methods.leverage().call()
  const cbbcType = await cbbcInstance.methods.cbbcType().call()
  const cbbcRouterV2Instance = await getCbbcRouterV2Instance()
  const config = await getNetworkConfig()
  const charmByte32 = '0x434841524d000000000000000000000000000000000000000000000000000000'


  const settleTokenAddr = config.cbbcType.find((i) => { return i.address === cbbcAddr }).settleToken
  const settleBytes32 = config.settleTokenList.find((i) => { return i.address === settleTokenAddr }).Bytes32
  const curTradeToken = config.tradeTokenList.find((i) => { return i.name === tradeType })

  const tradePriceInfo = await getTradePrice()
  if (tradePriceInfo.code === 0) {
    console.log(tradePriceInfo, 'tradePriceInfo')
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === curTradeToken.name })
    // return

    cbbcRouterV2Instance.methods.sellCbbcWithPermit(
      [priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature],//signedPrice
      settleBytes32,//charm bytes32
      [charmByte32, curTradeToken.Bytes32, leverage, cbbcType], //cTokenSettings
      toWei(amount), 0, ownerAddress, deadline,
      [false, permit.v, permit.r, permit.s]
    ).send({ from: ownerAddress }, async function (error, transactionHash) {
      callback(error, transactionHash)
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt)
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })

  }
}
//end
/* 获取某些权证的权证详情信息--价格，数量，开仓成本等
  ** accountAddr <String> : 账户地址
  ** cbbcTypeList <Array> : 权证类型list，来自于cbbcType --不传查所有种类权证
  */
async function getCbbcTypeInfo(accountAddr, cbbcTypeList) {


  const config = await getNetworkConfig()
  const cbbcType = cbbcTypeList || config.cbbcType // cbbcTypeList传值查询指定种类权证
  const cbbcRouterInstance = await getCbbcRouterInstance()
  const IssuerForCbbcTokenInstance = await getIssuerForCbbcTokenInstance()

  const cbbc = []
  const rebasePricePromise = []
  const cbbcpricePromise = []

  const mData = MarketData ? MarketData : await getMarketData(cbbcType)
  //console.log(mData, 'mData')

  const priceData = []
  const tradeTokenDecimals = 18
  const tradePriceInfo = getTradePrice()
  const currentTimestamp = (Math.floor(Date.now() / 1000)).toString()

  // const aa = new Date().getTime()
  for (let i = 0; i < cbbcType.length; i++) {
    const item = cbbcType[i]
    const tokenInstance = new web3.eth.Contract(cbbcToken.abi, item.address)
    rebasePricePromise.push(tokenInstance.methods.rebasePrice().call())
    rebasePricePromise.push(tokenInstance.methods.rebaseTimestamp().call())

    rebasePricePromise.push(tokenInstance.methods.balanceOf(accountAddr).call())
    rebasePricePromise.push(IssuerForCbbcTokenInstance.methods.getPurchaseCost(item.Bytes32, accountAddr).call())
  }
  rebasePricePromise.push(tradePriceInfo)// 最后一个是tradePriceInfo--标的物价格
  await Promise.all(rebasePricePromise).then(result => {
    for (let i = 0; i < result.length - 1; i += 4) {
      const currentPrice = result[result.length - 1].data.find((item) => { return item.tradeType === cbbcType[i / 4].tradeType })
      priceData.push({
        adderss: cbbcType[i / 4].address,
        rebasePrice: result[i],
        rebaseTimestamp: result[i + 1],
        amount: result[i + 2],
        purchaseCost: toEth(result[i + 3]),
        currentTimestamp: currentTimestamp,
        currentPrice: currentPrice.tradePrice
      })
    }
  })
  // const bb = new Date().getTime()
  // console.log(bb - aa, 'getCbbc----耗时计算000')

  for (let i = 0; i < cbbcType.length; i++) {
    const item = cbbcType[i]
    const settleBytes32 = config.settleTokenList.find((it) => { return it.address === item.settleToken }).Bytes32
    const tradeBytes32 = config.tradeTokenList.find((it) => { return it.name === item.tradeType }).Bytes32

    const tokenData = {
      settleBytes32,
      tradeBytes32,
      leverage: item.leverage,
      cbbcType: item.type
    }

    if (priceData[i].rebasePrice === '0' && priceData[i].rebaseTimestamp === '0') {
      cbbcpricePromise.push('none')
    } else {
      cbbcpricePromise.push(cbbcRouterInstance.methods.computeCbbcPrice(
        [mData[i].settleTokenPrice, priceData[i].rebasePrice, priceData[i].rebaseTimestamp, priceData[i].currentPrice, priceData[i].currentTimestamp],
        [tokenData.settleBytes32, tokenData.tradeBytes32, (tokenData.leverage).toString(), tokenData.cbbcType, mData[i].decimals, tradeTokenDecimals],
        [mData[i].beta, mData[i].alpha_buy, mData[i].alpha_sell, mData[i].baSpread, mData[i].iRate, mData[i].sigma, mData[i].dailyVolume],
        0, 0).call())
    }
  }

  await Promise.all(cbbcpricePromise).then(result => {
    let cbbcTypeLen = result.length
    for (let i = 0; i < cbbcTypeLen; i++) {
      let cbbcprice = toEth(result[i] === 'none' ? '1' : result[i])
      cbbcprice = cbbcprice === '0' ? '1' : cbbcprice
      cbbcprice = Number(cbbcprice).toFixed(4)
      cbbc.push({
        ...cbbcType[i],
        purchaseCost: priceData[i].purchaseCost,
        leverageLabel: `${cbbcType[i].leverage}X`, // 仅筛选功能逻辑用
        cbbcprice,
        tradePrice: priceData[i].currentPrice,
        amount: toEth(priceData[i].amount)
      })
    }
  })

  return cbbc
}

/* 获取为了’获取权证价格‘的参数
  ** cbbcTypeList <Array> : 权证种类list
  */

let MarketData = null
async function getMarketData(cbbcTypeList) {
  const res = []
  const config = await getNetworkConfig()
  const marketOracleInstance = await getMarketOracleInstance()
  const systemSettingsInstance = await getSystemSettingsInstance()
  const aa = new Date().getTime()

  // 获取alphBuy，alphaSell
  const systemSettingRes = { alphBuy: 0, alphaSell: 0 }
  await Promise.all([systemSettingsInstance.methods.alphaForBuy().call(), systemSettingsInstance.methods.alphaForSell().call()]).then(result => {
    systemSettingRes.alphBuy = result[0]
    systemSettingRes.alphaSell = result[1]
  })

  // 获取settleTokenPrice,iRate
  const settleTokenPricePromise = []
  const settlePriceIrate = []
  for (let i = 0; i < config.settleTokenList.length; i++) {
    const settleToken = config.settleTokenList[i]
    settleTokenPricePromise.push(marketOracleInstance.methods.settleTokenPrices(settleToken.Bytes32).call())
    settleTokenPricePromise.push(marketOracleInstance.methods.interestRates(settleToken.Bytes32).call())

    const settleCbbcERC20Instance = new web3.eth.Contract(CbbcERC20.abi, settleToken.address)
    settleTokenPricePromise.push(settleCbbcERC20Instance.methods.decimals().call())
  }
  await Promise.all(settleTokenPricePromise).then(result => {
    for (let i = 0; i < settleTokenPricePromise.length; i += 3) {
      settlePriceIrate.push({
        settleToken: config.settleTokenList[i / 3].address,
        price: result[i],
        iRate: result[i + 1],
        decimals: result[i + 2]
      })
    }
  })

  // 获取tradeTokenDatas
  const tradeTokenDatasPromise = []
  const tradeTokenDatasRes = []
  for (let i = 0; i < config.tradeTokenList.length; i++) {
    const tradeToken = config.tradeTokenList[i]
    tradeTokenDatasPromise.push(marketOracleInstance.methods.tradeTokenDatas(tradeToken.Bytes32).call())
  }
  await Promise.all(tradeTokenDatasPromise).then(result => {
    for (let i = 0; i < result.length; i++) {
      tradeTokenDatasRes.push({
        tradeType: config.tradeTokenList[i].name,
        baSpread: result[i].bidAskSpread,
        sigma: result[i].dailyPriceVolatility,
        dailyVolume: result[i].dailyVolume
      })
    }
  })

  // 获取beta(只和settleTokenKey, tradeTokenKey有关，种类为两者组合)
  const betaPromise = []
  const betaRes = []
  const betaResTemp = []
  for (let i = 0; i < config.settleTokenList.length; i++) {
    const settleItem = config.settleTokenList[i]
    for (let j = 0; j < config.tradeTokenList.length; j++) {
      const tradeItem = config.tradeTokenList[j]
      betaResTemp.push({ settleToken: settleItem.address, tradeType: tradeItem.name })
      betaPromise.push(marketOracleInstance.methods.betas(settleItem.Bytes32, tradeItem.Bytes32).call())
    }
  }
  await Promise.all(betaPromise).then(result => {
    for (let i = 0; i < result.length; i++) {
      betaRes.push({
        tradeType: betaResTemp[i].tradeType,
        settleToken: betaResTemp[i].settleToken,
        beta: result[i]
      })
    }
  })

  // console.log(settlePriceIrate, 'settlePriceIrate')
  // console.log(systemSettingRes, 'systemSettingRes')
  // console.log(tradeTokenDatasRes, 'tradeTokenDatasRes')
  // console.log(betaRes, 'betaRes')

  for (let i = 0; i < cbbcTypeList.length; i++) {
    const item = cbbcTypeList[i]

    const beta = betaRes.find((i) => { return i.tradeType === item.tradeType && i.settleToken === item.settleToken }).beta
    const tradeTokenDatas = tradeTokenDatasRes.find((i) => { return i.tradeType === item.tradeType })
    const priceIrate = settlePriceIrate.find((i) => { return i.settleToken === item.settleToken })

    const obj = {
      cbbcType: item,

      beta: beta,

      alpha_buy: systemSettingRes.alphBuy,
      alpha_sell: systemSettingRes.alphaSell,

      iRate: priceIrate.iRate,
      settleTokenPrice: priceIrate.price,
      decimals: priceIrate.decimals,

      baSpread: tradeTokenDatas.baSpread,
      sigma: tradeTokenDatas.sigma,
      dailyVolume: tradeTokenDatas.dailyVolume,
    }
    res.push(obj)
  }
  const cc = new Date().getTime()
  console.log(cc - aa, 'getMarketData耗时')
  MarketData = res
  return res
}

/* 获取 rebase价格
  *cbbcAddr <string>: 当前权证的address
  */
async function getRebasePrice(cbbcAddr) {
  const res = {}
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, cbbcAddr)
  const rebasePrice = tokenInstance.methods.rebasePrice().call()
  await Promise.resolve(rebasePrice).then(result => {
    res.value = toEth(result)
    res.address = cbbcAddr
  })
  return res
}

/* 获取开仓成本
  *Bytes32 <string>: 当前权证的Bytes32
  *accountAddress <string>: 当前用户账号
  */
async function getPurchaseCost(Bytes32, accountAddress) {
  let cost = 0
  const instance = await getIssuerForCbbcTokenInstance()
  cost = await instance.methods.getPurchaseCost(Bytes32, accountAddress).call()
  return toEth(cost)
}

/* 获取某种权证的持仓数量（也是最大平仓数）
  *cbbcAddr <string>: 当前权证的address
  */
async function getCbbcTokenSupply(cbbcAddr, accountAddress) {
  const instance = new web3.eth.Contract(CbbcERC20.abi, cbbcAddr)
  const amount = await instance.methods.balanceOf(accountAddress).call()
  return toEth(amount)
}

/* rebase操作
  *Bytes32 <string>: 当前权证的Bytes32
  *tradeType  <number>:标的物类型---BTC,UNI,ETH
  *ownerAddress  <string>:账户address
  *onSend:FUNCTION
  *onConfirm:FUNCTION
  */
async function rebase(Bytes32, tradeType, ownerAddress, onSend, onConfirm) {
  const cbbcRouterInstance = await getCbbcRouterInstance()
  const config = await getNetworkConfig()
  const curTradeToken = config.tradeTokenList.find((i) => { return i.name === tradeType })

  const tradePriceInfo = await getTradePrice()
  if (tradePriceInfo.code === 0) {
    const priceData = tradePriceInfo.data.find((item) => { return item.tradeType === curTradeToken.name })
    cbbcRouterInstance.methods.rebase([priceData.tradePrice, priceData.nonce, priceData.expireTimestamp, priceData.signature],
      curTradeToken.Bytes32, [Bytes32]).send({ from: ownerAddress }, function (hash) {
        onSend(hash) // onSend 回调函数用于loading效果
      }).on('transactionHash', function (receipt) {
        onConfirm(receipt)
      }).once('confirmation', function () {
        store.commit('upPendingStatus', false)
      })
  }
}

/*
  *Bytes32:结算币的address
  *accountAddress:账户address
  */
async function allowance(addr, accountAddress) {
  const config = await getNetworkConfig()
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, addr)
  return toEth(await tokenInstance.methods.allowance(accountAddress, config.addressBook.cbbcRouterAddress).call())
}
/*
  *Bytes32:结算币的address
  *accountAddress:账户address
  */
async function allowanceRouteV2(addr, accountAddress) {
  const config = await getNetworkConfig()
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, addr)
  return toEth(await tokenInstance.methods.allowance(accountAddress, config.addressBook.routerV2Address).call())
}

/*
  *Bytes32 <string >:结算币的Bytes32
  *accountAddress <string >:账户address
  */
async function getBalance(addr, accountAddress) {
  const instance = new web3.eth.Contract(cbbcToken.abi, addr)
  const amount = await instance.methods.balanceOf(accountAddress).call()
  return toEth(amount)
}


/*
  *accountAddress:账户address
  *callback:FUNCTION
  */
async function getETHBalance(accountAddress, callback) {
  web3.eth.getBalance(accountAddress, (err, balance) => {
    if (err != null) {
      console.log(err)
    }
    callback(toEth(balance))
  })
}

/*>>>>>>>>>>> 交易，持仓页（开，平仓等相关操作）end <<<<<<<<<<<<*/





















/*>>>>>>>>>>> 流动性页面用 start <<<<<<<<<<<<*/

/* 添加流动性
  * Bytes32:  对应结算币的Bytes32
  * amount:  数量
  * ownerAddress:  当前用户账号
  * callback: FUNCTION
  * onConfirm: FUNCTION
  */
async function addLiquidity(Bytes32, amount, ownerAddress, callback, onConfirm) {
  console.log(Bytes32, amount, ownerAddress)
  const cbbcRouterInstance = await getCbbcRouterInstance()
  cbbcRouterInstance.methods.addLiquidity(Bytes32, toWei(amount), ownerAddress, getDeadline())
    .send({ from: ownerAddress }, async function (error, transactionHash) {
      callback(error, transactionHash)
      // }).once('confirmation', function (confNumber, receipt) {
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt)
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })
}

/* 添加流动性（ETH,BNB,HT等结算币）
  * amount:  数量
  * ownerAddress:  当前用户账号
  * callback: FUNCTION
  * onConfirm: FUNCTION
  */
async function addLiquidityETH(amount, ownerAddress, callback, onConfirm) {
  const cbbcRouterInstance = await getCbbcRouterInstance()
  cbbcRouterInstance.methods.addLiquidityETH(ownerAddress, getDeadline())
    .send({ from: ownerAddress, value: toWei(amount) }, async function (error, transactionHash) {
      callback(error, transactionHash)
      // }).once('confirmation', function (confNumber, receipt) {
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt)
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })
}

/* 移除流动性--批准(也用于流动性币开仓的批准)
  * name:  结算币的name
  * amount:  数量
  * ownerAddress:  当前用户账号
  * callback: FUNCTION
  * return < string >
  */
async function getLiquiditySignature(name, amount, ownerAddress, callback) {
  const instance = await getLiquidityTokenInstance(name)
  const nonce = await instance.methods.nonces(ownerAddress).call()
  const scName = await instance.methods.name().call()
  const liquidityTokenAddress = await getLiquidityTokenAddress(name)
  // console.log(scName, nonce,liquidityTokenAddress, 'scName, nonce, config.liquidityTokenAddress[name]')
  signature('', scName, nonce, liquidityTokenAddress, amount, ownerAddress, callback)
}

/* 移除流动性
  * Bytes32:  对应结算币的Bytes32
  * amount:  数量
  * ownerAddress:  当前用户账号
  * permit:  permit
  * deadline:  deadline
  * callback: FUNCTION
  * onConfirm: FUNCTION
  */
async function removeLiquidityWithPermit(Bytes32, amount, ownerAddress, permit, deadline, callback, onConfirm) {
  const cbbcRouterInstance = await getCbbcRouterInstance()
  cbbcRouterInstance.methods.removeLiquidityWithPermit(Bytes32, toWei(amount), 0, ownerAddress, deadline,
    [false, permit.v, permit.r, permit.s])
    .send({ from: ownerAddress }, async function (error, transactionHash) {
      callback(error, transactionHash)
      // }).once('confirmation', function (confNumber, receipt) {
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt)
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })
}

/* 移除流动性（ETH,BNB,HT等结算币）
  * amount:  数量
  * ownerAddress:  当前用户账号
  * permit:  permit
  * deadline:  deadline
  * callback: FUNCTION
  * onConfirm: FUNCTION
  */
async function removeLiquidityETHWithPermit(amount, ownerAddress, permit, deadline, callback, onConfirm) {
  const cbbcRouterInstance = await getCbbcRouterInstance()
  cbbcRouterInstance.methods.removeLiquidityETHWithPermit(
    toWei(amount), 0, ownerAddress, deadline,
    [false, permit.v, permit.r, permit.s]
  ).send({ from: ownerAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
  }).on('transactionHash', function (receipt) {
    onConfirm(receipt)
  }).once('confirmation', function () {
    store.commit('upPendingStatus', false)
  })
}

/* 持有流动性数量
  * name: 对应流动币的name
  * ownerAddress: 当前用户账号
  * return < string >
  */
async function getLiquilityBalance(name, ownerAddress) {
  let amount = 0
  const instance = await getLiquidityTokenInstance(name)
  amount = await instance.methods.balanceOf(ownerAddress).call()
  return toEth(amount)
}


/* 持有流动性数量 --- （待废弃，与getLiquilityBalance方法重复）
  * liquidityTokenAddr: 对应流动币的address
  * ownerAddress: 当前用户账号
  * return < string >
  */
async function getOwnerLiquilityBalance(liquidityTokenAddr, ownerAddress) {
  const instance = new web3.eth.Contract(CbbcERC20.abi, liquidityTokenAddr)
  return new Promise((resolve, reject) => {
    instance.methods.balanceOf(ownerAddress).call().then((res) => {
      resolve(toEth(res))
    }).catch((err) => { reject(err) })
  })
}

/* 流动性总量
  * liquidityTokenAddr: 对应流动币的address
  * return < string >
  */
async function getLiquidityTotalSupply(liquidityTokenAddr) {
  const instance = new web3.eth.Contract(CbbcERC20.abi, liquidityTokenAddr)
  return new Promise((resolve, reject) => {
    instance.methods.totalSupply().call().then((res) => {
      resolve(toEth(res))
    }).catch((err) => { reject(err) })
  })
}

/* 结算币总量
  * settleTokenAddress: 结算币的address
  * liquidityTokenAddr: 对应流动币的address
  * return < string >
  */
async function getSettleTokenTotalSupply(settleTokenAddress, liquidityTokenAddr) {
  const instance = new web3.eth.Contract(CbbcERC20.abi, settleTokenAddress)
  return new Promise((resolve, reject) => {
    instance.methods.balanceOf(liquidityTokenAddr).call().then((res) => {
      resolve(toEth(res))
    }).catch((err) => { reject(err) })
  })
}

/* 总开仓量
  * Bytes32:  结算币的Bytes32
  * return < string >
  */
async function getTotalLiabilities(Bytes32) {
  const instance = await getIssuerForCbbcTokenInstance()
  return new Promise((resolve, reject) => {
    instance.methods.totalIssuedCTokens(Bytes32).call().then((res) => {
      resolve(to4DecimalString(toEth(res)))
    }).catch((err) => { reject(err) })
  })
}

/*>>>>>>>>>>> 流动性页面用 end <<<<<<<<<<<<*/



















/*>>>>>>>>>>> 质押页面 start <<<<<<<<<<<<*/

/* 获取池子信息
  * 注意poolId就是数组的index（通过liquidityTokenAddr查询到index）
  * return < Array >
  */
async function getPoolInfo() {
  const cppcChefInstance = await getCppcChefInstance()
  const length = await cppcChefInstance.methods.poolLength().call()
  const poolInfoPromise = []
  const poolInfo = []
  for (let i = 0; i < length; i++) {
    poolInfoPromise.push(cppcChefInstance.methods.poolInfo(i).call())
    poolInfoPromise.push(cppcChefInstance.methods.lpToken(i).call())
  }

  await Promise.all(poolInfoPromise).then(result => {
    for (let i = 0; i < result.length; i += 2) {
      const liquidityTokenAddr = result[i + 1]
      // //zhh 20230107 去掉质押 CNYT
      // if (liquidityTokenAddr == "0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961") {
      //   continue;
      // }
      poolInfo.push({
        accSushiPerShare: result[i].accSushiPerShare,
        allocPoint: result[i].allocPoint,
        lastRewardTime: result[i].lastRewardTime,
        liquidityTokenAddr: liquidityTokenAddr
      })
    }
  })
  console.log(poolInfo, 'poolInfo')
  return poolInfo
}

/* 获取charm价格 （用于计算APR）
  * return < string >
  */
async function getCHARMPrice() {
  const config = await getNetworkConfig()
  const item = config.settleTokenList.find((i) => { return i.name === 'CHARM' })
  const marketOracleInstance = await getMarketOracleInstance()
  const bb = await marketOracleInstance.methods.settleTokenPrices(item.Bytes32).call()
  return toEth(bb)
}
//zhh 20221208
/* 获取CNYT价格 （用于计算APR）
  * return < string >
  */
async function getCNYTPrice() {
  const config = await getNetworkConfig()
  const item = config.settleTokenList.find((i) => { return i.name === 'CNYT' })
  const marketOracleInstance = await getMarketOracleInstance()
  const bb = await marketOracleInstance.methods.settleTokenPrices(item.Bytes32).call()
  return toEth(bb)
}
//end

/* 获取收益
  * pid: 当前币种的poolId（在池子里的index值）
  * poolInfo: 池子详情（是个数组）
  * return < string >
  */
async function getTokenRewards(pid, poolInfo) {
  const cppcChefInstance = await getCppcChefInstance()
  const totalAllocPoint = cppcChefInstance.methods.totalAllocPoint().call()
  const sushiPerSecond = cppcChefInstance.methods.sushiPerSecond().call()

  return new Promise((resolve, reject) => {
    Promise.all([totalAllocPoint, sushiPerSecond]).then((results) => {
      const a = toEth(poolInfo[pid].allocPoint / results[0] * results[1] * 86400)
      resolve(a)
    }).catch((err) => { reject(err) })
  })
}

/* 获取TVL
  * Bytes32: 对应结算币的Bytes32
  * liquidityTokenAddress: 对应结算币的liquidityTokenAddress
  * return < string >
  */
async function getTVL(Bytes32, liquidityTokenAddress) {
  const config = await getNetworkConfig()
  const instance = new web3.eth.Contract(CbbcERC20.abi, liquidityTokenAddress)
  const marketOracleInstance = await getMarketOracleInstance()

  const aa = instance.methods.balanceOf(config.addressBook.cppcChefAddress).call()
  const bb = marketOracleInstance.methods.settleTokenPrices(Bytes32).call()

  return new Promise((resolve, reject) => {
    Promise.all([aa, bb]).then((results) => {
      console.log((toEth(results[0]) * toEth(results[1])).toFixed(4), '(tvlForToken1 + tvlForToken2).toFixed(4)')

      resolve((toEth(results[0]) * toEth(results[1])).toFixed(4))
    }).catch(function (err) {
      console.log(err)
      reject(err)
    })
  })
}


//zhh 20230107
// 获取powerswap 指定pool币对的余额
async function getBalanceOfPowerSwap(tokenName, poolAddress) {
  const config = await getNetworkConfig()
  const token = config.settleTokenList.find((i) => { return i.name === tokenName })
  const instanceForToken = new web3.eth.Contract(CbbcERC20.abi, token.address)
  const tokenBalance = await instanceForToken.methods.balanceOf(poolAddress).call()
  console.log("zhh getBalanceOfPowerSwap "+tokenName+",tokenBalance:"+tokenBalance)
  return toEth(tokenBalance)
}
//zhh end

/* 获取TVL(total value locked)（来自Powerswap的质押）
  * name: 对应结算币的name
  * liquidityTokenAddress: 对应结算币的liquidityTokenAddress
  * return < string >
  */
async function getTVLForPowerswap(name, liquidityTokenAddress) {
  const nameArray = name.split('/')
  const config = await getNetworkConfig()
  const Token1 = config.settleTokenList.find((i) => { return i.name === nameArray[0] })
  const Token2 = config.settleTokenList.find((i) => { return i.name === nameArray[1] })// CHARM

  const instance = new web3.eth.Contract(CbbcERC20.abi, liquidityTokenAddress)
  const instanceForToken1 = new web3.eth.Contract(CbbcERC20.abi, Token1.address)
  const instanceForToken2 = new web3.eth.Contract(CbbcERC20.abi, Token2.address)
  const marketOracleInstance = await getMarketOracleInstance()

  const liquidityStaked = instance.methods.balanceOf(config.addressBook.cppcChefAddress).call()
  const liquidityInTotal = instance.methods.totalSupply().call()

  const token1InPool = instanceForToken1.methods.balanceOf(liquidityTokenAddress).call()
  const token2InPool = instanceForToken2.methods.balanceOf(liquidityTokenAddress).call()

  const token1Price = marketOracleInstance.methods.settleTokenPrices(Token1.Bytes32).call()
  const token2Price = marketOracleInstance.methods.settleTokenPrices(Token2.Bytes32).call()

  return new Promise((resolve, reject) => {
    Promise.all([liquidityStaked, liquidityInTotal, token1InPool, token2InPool, token1Price, token2Price]).then((results) => {
      const tvlForToken1 = toEth(results[2]) * toEth(results[4]) * (results[0] / results[1]) // 需要注意一下decimals， 可能需要除以10^18
      const tvlForToken2 = toEth(results[3]) * toEth(results[5]) * (results[0] / results[1])
      console.log((tvlForToken1 + tvlForToken2).toFixed(4), '(tvlForToken1 + tvlForToken2).toFixed(4)')
      resolve((tvlForToken1 + tvlForToken2).toFixed(4))
    }).catch((err) => { reject(err) })
  })
}

/* 获取‘可获取’的值
  * poolid:  当前币种的poolId（在池子里的index值）
  * ownerAddress: 当前用户账号
  * return < string >
  */
async function getHarvest(poolid, ownerAddress) {
  const cppcChefInstance = await getCppcChefInstance()
  return new Promise((resolve, reject) => {
    cppcChefInstance.methods.pendingSushi(poolid, ownerAddress).call().then((res) => {
      console.log(res, 'resresres')
      resolve(toEth(res))
    }).catch((err) => { reject(err) })
  })
}

/* 获取‘已质押’的数量
  * poolid:  当前币种的poolId（在池子里的index值）
  * ownerAddress: 当前用户账号
  * return < string >
  */
async function getStakeOfPool(poolid, ownerAddress) {
  const cppcChefInstance = await getCppcChefInstance()
  const result = cppcChefInstance.methods.userInfo(poolid, ownerAddress).call()

  return new Promise((resolve, reject) => {
    Promise.all([result]).then((results) => {
      console.log(toEth(results[0].amount), 'resresres2')
      resolve(toEth(results[0].amount))
    }).catch((err) => { reject(err) })
  })
}

/* 获取‘未质押’的数量---其实就是持有流动性数量
  * liquidityTokenName:  当前币种的流动币的name
  * ownerAddress: 当前用户账号
  * return < string >
  */
async function getBalanceOfPool(liquidityTokenName, ownerAddress) {
  // const instance = await getLiquidityTokenInstance(liquidityTokenName)
  // return toEth(await instance.methods.balanceOf(ownerAddress).call())
  return await getLiquilityBalance(liquidityTokenName, ownerAddress)
}

/* 抵押--批准
  * liquidityTokenAddr: 当前币种的liquidityTokenAddr
  * amount: 数量
  * ownerAddress: 当前用户账号
  * callback:FUNCTION
  * onConfirm:FUNCTION
  */
async function approveDeposit(liquidityTokenAddr, amount, ownerAddress, callback, onConfirm) {
  console.log(liquidityTokenAddr, 'liquidityTokenAddrliquidityTokenAddr')
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, liquidityTokenAddr)
  const config = await getNetworkConfig()
  const cppcChefAddress = config.addressBook.cppcChefAddress
  tokenInstance.methods.approve(cppcChefAddress, toWei(amount)).send({ from: ownerAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
    // }).once('confirmation', function (confNumber, receipt) {
  }).on('confirmation', function (receipt) { // 此处approve成功，返回回调（此时可以进行开仓等操作）
    onConfirm(receipt)
  })
}

/* 取消抵押--批准
  * amount: 数量
  * ownerAddress: 当前用户账号
  * callback:FUNCTION
  * onConfirm:FUNCTION
  */
async function approveWithdraw(amount, ownerAddress, callback, onConfirm) {
  const config = await getNetworkConfig()
  const cppcChefAddress = config.addressBook.cppcChefAddress
  const tokenInstance = new web3.eth.Contract(cbbcToken.abi, '0xA489fd5a8192b27d06d207cF9caB33e392717CaC')
  tokenInstance.methods.approve(cppcChefAddress, toWei(amount)).send({ from: ownerAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
    // }).once('confirmation', function (confNumber, receipt) {
  }).on('confirmation', function (receipt) { // 此处approve成功，返回回调（此时可以进行开仓等操作）
    onConfirm(receipt)
  })
}

/* 质押
  * poolid:  当前币种的poolId（在池子里的index值）
  * amount: 数量
  * ownerAddress: 当前用户账号
  * callback:FUNCTION
  * onConfirm:FUNCTION
  */
async function deposit(poolid, amount, ownerAddress, callback, onConfirm) {
  const cppcChefInstance = await getCppcChefInstance()
  cppcChefInstance.methods.deposit(poolid, toWei(amount), ownerAddress).send({ from: ownerAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
  }).on('transactionHash', function (receipt) {
    onConfirm(receipt)
  }).once('confirmation', function () {
    store.commit('upPendingStatus', false)
  })
}

/* 取消质押
  * poolid:  当前币种的poolId（在池子里的index值）
  * amount: 数量
  * ownerAddress: 当前用户账号
  * callback:FUNCTION
  * onConfirm:FUNCTION
  */
async function withdrawAndHarvest(poolid, amount, ownerAddress, callback, onConfirm) {
  const cppcChefInstance = await getCppcChefInstance()
  cppcChefInstance.methods.withdrawAndHarvest(poolid, toWei(amount), ownerAddress).send({ from: ownerAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
    // }).once('confirmation', function (confNumber, receipt) {
  }).on('transactionHash', function (receipt) {
    onConfirm(receipt)
  }).once('confirmation', function () {
    store.commit('upPendingStatus', false)
  })
}

/* 获取操作
  * poolid:  当前币种的poolId（在池子里的index值）
  * ownerAddress: 当前用户账号
  * callback:FUNCTION
  * onConfirm:FUNCTION
  */
async function harvest(poolid, ownerAddress, callback, onConfirm) {
  const cppcChefInstance = await getCppcChefInstance()
  cppcChefInstance.methods.harvest(poolid, ownerAddress).send({ from: ownerAddress }, async function (error, transactionHash) {
    callback(error, transactionHash)
  }).once('confirmation', function (confNumber, receipt) {
    onConfirm(confNumber, receipt)
  })
}

/*>>>>>>>>>>> 质押页面 end <<<<<<<<<<<<*/


















/*>>>>>>>>>>> 分红币页面 start <<<<<<<<<<<<*/

/* 兑换,销毁分红币的批准 （线下approve)
  *address:(兑换:charm的address,销毁:当前分红币的address）
  *amount: 数量
  *accountAddress:账户address
  *callback:FUNCTION
  *isToWei:Bolean
  */
async function getDividendTokenSignature(address, amount, ownerAddress, callback, isToWei = true) {
  const instance = new web3.eth.Contract(cbbcToken.abi, address)
  const nonce = await instance.methods.nonces(ownerAddress).call()
  const scName = await instance.methods.name().call()
  signature('', scName, nonce, address, amount, ownerAddress, callback, isToWei)
}

/* 兑换分红币 (线下approve 线上交易)
  *settleBytes32:结算币的address
  *amount:数量
  *accountAddress:账户address
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function buyDividendTokenWithPermit(settleBytes32, amount, accountAddress, deadline, permit, callback, onConfirm) {
  const Instance = await getCbbcRouterInstance()
  // console.log(settleBytes32, amount, accountAddress, deadline, permit, 'settleBytes32, amount, accountAddress, deadline, permit')
  Instance.methods.buyDividendTokenWithPermit(settleBytes32, toWei(amount), accountAddress, deadline, [false, permit.v, permit.r, permit.s])
    .send({ from: accountAddress }, async function (error, transactionHash) {
      callback(error, transactionHash)
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt)
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })
}


/* 销毁分红币 (线下approve 线上交易)
  *settleBytes32:结算币的address
  *amount:数量
  *accountAddress:账户address
  *deadline:deadline
  *permit:permit
  *callback:FUNCTION
  *onConfirm:FUNCTION
  */
async function sellDividendTokenWithPermit(settleBytes32, amount, accountAddress, deadline, permit, callback, onConfirm) {
  const Instance = await getCbbcRouterInstance()
  // console.log(settleBytes32, amount, accountAddress, deadline, permit, 'settleBytes32, amount, accountAddress, deadline, permit')
  Instance.methods.sellDividendTokenWithPermit(settleBytes32, amount, 0, accountAddress, deadline, [false, permit.v, permit.r, permit.s])
    .send({ from: accountAddress }, async function (error, transactionHash) {
      callback(error, transactionHash)
    }).on('transactionHash', function (receipt) {
      onConfirm(receipt)
    }).once('confirmation', function () {
      store.commit('upPendingStatus', false)
    })
}

/* 获取 分红币数量
  *dividendTokenAddress: 当前分红币的address
  *accountAddress:账户address
  */
async function getStakeOfDivid(dividendTokenAddress, accountAddress) {
  const instance = new web3.eth.Contract(CbbcERC20.abi, dividendTokenAddress)
  return new Promise((resolve, reject) => {
    instance.methods.balanceOf(accountAddress).call().then((res) => {
      resolve(res)
    }).catch((err) => { reject(err) })
  })
}

/* 获取 分红币总量
  *dividendTokenAddress: 当前分红币的address
  */
async function getTotalOfDivid(dividendTokenAddress) {
  const instance = new web3.eth.Contract(CbbcERC20.abi, dividendTokenAddress)
  return new Promise((resolve, reject) => {
    instance.methods.totalSupply().call().then((res) => {
      resolve(res)
    }).catch((err) => { reject(err) })
  })
}

/* 获取 分红币--池子可获取利润
  *settleTokenKey: 当前分红币对应结算币的Bytes32
  */
async function getClaimableProfitslOfDivid(settleTokenKey) {
  const instance = await getIssuerForCbbcTokenInstance()
  return new Promise((resolve, reject) => {
    instance.methods.claimableProfits(settleTokenKey).call().then((res) => {
      resolve(res)
    }).catch((err) => { reject(err) })
  })
}

/*>>>>>>>>>>> 分红币页面 end <<<<<<<<<<<<*/

















/*>>>>>>>>>>> MetaMask插件相关操作 start <<<<<<<<<<<<*/
//相关文档 https://docs.metamask.io/guide/


// 连接用户钱包 pc插件
// arguments: fcuntion accountHandler, function chainIdHandler
async function connectWallet(accountHandler, chainIdHandler) {
  const provider = await detectEthereumProvider()

  if (!provider) {
    console.log('Please install MetaMask!')
  } else if (provider !== window.ethereum) {
    console.error('Do you have multiple wallets installed?')
  } else {
    window.ethereum.request({ method: 'eth_requestAccounts' }).then((result) => {
      handleAccountsChanged(result)
      accountHandler(result)
    }).catch((err) => {
      if (err.code === 4001) {
        console.log('Please connect to MetaMask.')
      } else {
        console.error(err)
      }
    })
    window.ethereum.request({ method: 'eth_chainId' }).then((result) => {
      // handleChainChanged(result)
      console.log(result, 'eth_chainIdeth_chainId')

      chainIdHandler(result)
    }).catch((err) => {
      if (err.code === 4001) {
        console.log('Please connect to MetaMask.')
      } else {
        console.error(err)
      }
    })
    window.ethereum.on('chainChanged', () => { // 监听切换网络
      window.location.reload()
    })
    window.ethereum.on('message', (msn) => { // 监听消息
      if (msn.type === 'eth_subscription') {
        // console.log(msn, '监听消息msn')
      }
    })
    window.ethereum.on('disconnect', (msn) => { // disconnect
      console.log(msn, 'disconnect')
    })
  }
}

function getAccount(accountHandler) { // 获取用户账户
  window.ethereum
    .request({ method: 'eth_accounts' })
    .then((result) => {
      handleAccountsChanged(result)
      accountHandler(result)
    })
    .catch((err) => {
      console.error(err)
    })
}

window.addEventListener('load', function () {
  if (typeof ethereum !== 'undefined') {
    window.ethereum.on('accountsChanged', handleAccountsChanged)
  }
})

async function walletConnect(accountHandler, chainIdHandler) {
  const provider = new WalletConnectProvider({
    infuraId: '27e484dcd9e3efcfd25a83a78777cdf1'
  })

  //  Enable session (triggers QR Code modal)
  await provider.enable()

  web3 = new Web3(provider)
  const accounts = await web3.eth.getAccounts()

  //  Get Chain Id
  const chainId = await web3.eth.getChainId()
  accountHandler(accounts)
  chainIdHandler(chainId)
}

async function handleAccountsChanged(accounts) {
  if (accounts.length === 0) {
    console.log('Please connect to MetaMask.')
  } else if (accounts[0] !== web3.eth.defaultAccount) {
    web3.eth.defaultAccount = accounts[0]
  }
}

function IsWalletInstalled() { // 是否安装钱包插件
  return typeof ethereum !== 'undefined'
}

/*>>>>>>>>>>> MetaMask 插件相关操作 end <<<<<<<<<<<<*/














export default {
  getSettleTokenListByConfig,
  getTradeTokenListByConfig,
  IsWalletInstalled,
  walletConnect,
  getETHBalance, // 获取用户ETH数量
  allowance, // 获取授权通证数量
  approveToken, // 授权通证
  //zhh
  approveTokenRouteV2, // 授权通证
  allowanceRouteV2,
  //end
  approveDeposit, // 授权质押
  approveWithdraw, // 授权取出质押
  getPurchaseCost,
  getBalance, // 获取通证数量
  buyCbbc, // 购买牛熊证
  buyCbbcETH, // 用ETH购买牛熊证

  buyCbbcUsingLiquidityTokenWithPermit, // 流动币 购买牛熊证
  sellSignature, // 平仓签名
  sellCbbcWithPermit, // 用线下签名来出售牛熊证
  rebase, // rebase
  getTotalLiabilities, // 获取流动性收益
  getLiquilityBalance, // 持有流动性数量
  getOwnerLiquilityBalance, // 持有流动性数量
  addLiquidity, // 添加流动性
  addLiquidityETH, // 用ETH添加流动性
  getLiquidityTotalSupply,
  getSettleTokenTotalSupply,
  removeLiquidityWithPermit, // 使用线下签名来移除流动性
  removeLiquidityETHWithPermit, // ETH使用线下签名来移除流动性
  getLiquiditySignature, // 移除流动性签名
  getCbbcTypeInfo, // 获取用户持仓列表


  connectWallet, // 连接钱包
  getAccount,
  to4DecimalString,
  getTradePrice, // 获取价格信息
  getTVL,
  getTVLForPowerswap,
  getTokenRewards,
  getPoolInfo, // all pool informations
  deposit, // stake liquidity token for cppc
  withdrawAndHarvest, // unstake liquidity token
  harvest,
  getHarvest,
  getBalanceOfPool,
  getStakeOfPool,
  toEth,
  toWei,
  getRebasePrice,
  buyDividendTokenWithPermit,
  sellDividendTokenWithPermit,
  getDividendTokenSignature,
  getStakeOfDivid,
  getTotalOfDivid, // 分红币总量
  getClaimableProfitslOfDivid,
  getCHARMPrice,
  //zhh 20221208
  getCNYTPrice,
  buyCbbcRouteV2,
  sellCbbcWithPermitRouteV2,
  //end
  //zhh 20230107
  getBalanceOfPowerSwap,
  //end

  getCbbcTokenSupply
}
