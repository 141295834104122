
// kovan网络
const kovanNet = {
  chainId: 42,
  netUrl: 'https://kovan.etherscan.io',
  name: 'Ethereum Testnet Kovan',
  shortName: 'kov',
  chain: 'ETH',
  unit: 'ETH',
  networkName: 'Kovan',
  tradeSuccessTips: '在KOVANSCAN上查看',
  tradeType: ['BTC', 'UNI', 'ETH'], // 标的物类型
  liquidityTokenAddress: { //  结算币对应流动币的address
    ETH: '0x9E327ecCB5b93aD01e375691B250D461472cCceF',
    TKN4: '0x448fcB920892f0cd9b70E4fbE1e66cd63b9AfE54',
    CHARM: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509', // 注： CHARM不存在对应的流动币,liquidityAddress就是本身结算币address
    'ETH/CHARM': '0x190819cbF86B6451F91C97668312bD4c2a65485d', // 从powerswap过来的质押币 就是对应的address
    'TKN4/CHARM': '0xCf240Fb7Ee4250d1C501547B867621Deb9c0fE55'
  },
  tradeTokenList: [// 当前网络下 标的物类型
    {
      Bytes32: '0x4254430000000000000000000000000000000000000000000000000000000000',
      name: 'BTC'
    },
    {
      Bytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      name: 'ETH'
    },
    {
      Bytes32: '0x554e490000000000000000000000000000000000000000000000000000000000',
      name: 'UNI'
    }
  ],
  settleTokenList: [// 结算币种类
    {
      address: '0xb39466b1282155b1251eab2bb0d5381b6eb66567',
      liquidityTokenAddr: '0x448fcB920892f0cd9b70E4fbE1e66cd63b9AfE54', // 对应流动币的address
      Bytes32: '0x6c55534454000000000000000000000000000000000000000000000000000000',
      name: 'TKN4',
      logoName: 'TKN4.png'// logo 前端逻辑用
    },
    {
      address: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
      liquidityTokenAddr: '0x9E327ecCB5b93aD01e375691B250D461472cCceF',
      Bytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      name: 'ETH',
      logoName: 'ETH.png'
    },
    {
      address: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509',
      liquidityTokenAddr: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509',
      Bytes32: '0x434841524d000000000000000000000000000000000000000000000000000000',
      name: 'CHARM',
      logoName: 'CHARM.png'
    }
  ],
  powerswapSettleTokenList: [// 从powerswap过来的质押币
    {
      name: 'ETH/CHARM',
      Bytes32: '0x4554482f434841524d0000000000000000000000000000000000000000000000',
      address: '0x190819cbF86B6451F91C97668312bD4c2a65485d',
      liquidityTokenAddr: '0x190819cbF86B6451F91C97668312bD4c2a65485d',
      logoName: 'ETH-CHARM.png', // log 前端逻辑用
      isPowerswap: true// 前端逻辑用
    },
    {
      name: 'TKN4/CHARM',
      Bytes32: '0x544b4e342f434841524d00000000000000000000000000000000000000000000',
      address: '0xCf240Fb7Ee4250d1C501547B867621Deb9c0fE55',
      liquidityTokenAddr: '0xCf240Fb7Ee4250d1C501547B867621Deb9c0fE55',
      logoName: 'TKN4-CHARM.png',
      isPowerswap: true
    }
  ],
  tradeLiquiditySettleTokenList: [// 能开仓的流动币类型
    {
      address: '0x448fcB920892f0cd9b70E4fbE1e66cd63b9AfE54',
      liquidityTokenAddr: '0x448fcB920892f0cd9b70E4fbE1e66cd63b9AfE54', // 仅方便前端逻辑处理，就是address的值
      Bytes32: '0x6c544b4e34000000000000000000000000000000000000000000000000000000',
      settleBytes32: '0x6c55534454000000000000000000000000000000000000000000000000000000', // 流动币对应的结算币的Bytes32
      isLiquidity: true, // 是否是流动币，前端自定义
      name: 'lTKN4'
    },
    {
      address: '0x9E327ecCB5b93aD01e375691B250D461472cCceF',
      liquidityTokenAddr: '0x9E327ecCB5b93aD01e375691B250D461472cCceF',
      Bytes32: '0x6c48540000000000000000000000000000000000000000000000000000000000',
      settleBytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      isLiquidity: true,
      name: 'lETH'
    }
  ],
  dividendSettleTokenList: [// 分红币币种类
    {
      address: '0xe12C74F87a2Deb26076BB16f1e8fc412944f68f3',
      settleTokenAddr: '0xb39466b1282155b1251eab2bb0d5381b6eb66567', //  对应的结算币的address
      Bytes32: '0x64544b4e34000000000000000000000000000000000000000000000000000000',
      name: 'dTKN4'
    },
    {
      address: '0x9965d213608eB11E6097e3bBC18537c1D6d33366',
      settleTokenAddr: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
      Bytes32: '0x6445544800000000000000000000000000000000000000000000000000000000',
      name: 'dETH'
    }
  ],
  addressBook: { // 各种 address(实例化各类abi合约)
    SystemSettingsAddress: '0xD59f92D63e7Df796e9D2798D9e2a06ba3AED9976',
    cbbcRouterAddress: '0x0dbD69d6F4B187B5075052465C7ce0868F68fE2A',
    orchestratorAddress: '0xEF1B5eE21bC55592b30c8ed85eB66cAb67A8110D',
    marketOracleAddress: '0xC68A93B2BB86192B544456A24c6F6234A0961508',

    wethAddress: '0xd0a1e359811322d97991e03f863a0c30c2cf029c', // 注：就是结算币ETH的address（heco下就是HT的address）
    addressResolver: '0xfF46780c39C878B7f4aF4FB8029e8b01F7157f19',

    cppcChefAddress: '0x2F54904AD371235c135697fD78612808c3dFbbd3',
    IssuerForCbbcTokenAddress: '0xa314dF08ed450d8f3A54D6Fbf6A4E920A6F40DC2'
  },
  cbbcType: [// 各类 权证信息
    {
      name: '1X BTC ETH', // 名称：杠杆倍数 - 牛证还是熊证 - 标的物 - 结算币
      type: '1', // 1是牛证。0是熊证
      leverage: 1, // 杠杆倍数
      tradeType: 'BTC', // 标的物种类
      settleType: 'ETH', // 结算币种类
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c', // 对应结算币的address
      Bytes32: '0x6331425443455448000000000000000000000000000000000000000000000000', // 权证的Bytes32
      address: '0x55bCF6D8C6BB9692048f4Edb899a196532bCf1Ee'// 权证的address
    },
    {
      name: '1X Inverse BTC ETH',
      type: '0',
      leverage: 1,
      tradeType: 'BTC',
      settleType: 'ETH',
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
      Bytes32: '0x6331494254434554480000000000000000000000000000000000000000000000',
      address: '0x555A989853aDa5F4C059717cF79A22ce23a74D4A'
    },
    {
      name: '2X BTC ETH',
      type: '1',
      tradeType: 'BTC',
      settleType: 'ETH',
      Bytes32: '0x6332425443455448000000000000000000000000000000000000000000000000',
      address: '0xfAF2E35324668C5c464D859462f842fE4C02EFCF',
      leverage: 2,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '2X Inverse BTC ETH',
      type: '0',
      tradeType: 'BTC',
      settleType: 'ETH',
      Bytes32: '0x6332494254434554480000000000000000000000000000000000000000000000',
      address: '0xb953A0908d9Adcc08bd69152Bb9206fD35eAAE59',
      leverage: 2,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '3X BTC ETH',
      type: '1',
      tradeType: 'BTC',
      settleType: 'ETH',
      Bytes32: '0x6333425443455448000000000000000000000000000000000000000000000000',
      address: '0x24C79B59c34Ab647a9f29E9187dDF3b5546813f3',
      leverage: 3,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '3X Inverse BTC ETH',
      type: '0',
      tradeType: 'BTC',
      settleType: 'ETH',
      Bytes32: '0x6333494254434554480000000000000000000000000000000000000000000000',
      address: '0x705Afc3a12e26AD433C77eF1bD751463E07ABd56',
      leverage: 3,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '5X BTC ETH',
      type: '1',
      tradeType: 'BTC',
      settleType: 'ETH',
      Bytes32: '0x6335425443455448000000000000000000000000000000000000000000000000',
      address: '0x816304B8d05e7Ac0d7fca6710CBAA7f1be030e19',
      leverage: 5,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '5X Inverse BTC ETH',
      type: '0',
      tradeType: 'BTC',
      settleType: 'ETH',
      Bytes32: '0x6335494254434554480000000000000000000000000000000000000000000000',
      address: '0xa7eBbbcf97bBe884Ffbc54DB31846FE870d26E64',
      leverage: 5,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },

    {
      name: '1X ETH ETH',
      type: '1',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6331455448455448000000000000000000000000000000000000000000000000',
      address: '0x6E9e12F3EEdF97e2CF5eccc024aF12Cc481b8575',
      leverage: 1,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '1X Inverse ETH ETH',
      type: '0',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6331494554484554480000000000000000000000000000000000000000000000',
      address: '0x3E0248457064346cB63Ef11C8d242e658BD16282',
      leverage: 1,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '2X ETH ETH',
      type: '1',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6332455448455448000000000000000000000000000000000000000000000000',
      address: '0x3FD7b776eD0dFD84eaA7d8F0e7AC965BeB2dDC9E',
      leverage: 2,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '2X Inverse ETH ETH',
      type: '0',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6332494554484554480000000000000000000000000000000000000000000000',
      address: '0x64A0d28a3aDafb408A8fc74Df4F35b9dde3ed649',
      leverage: 2,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '3X ETH ETH',
      type: '1',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6333455448455448000000000000000000000000000000000000000000000000',
      address: '0x6961e325B1E5eF519C9a2eff2941E9Fcc036daD5',
      leverage: 3,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '3X Inverse ETH ETH',
      type: '0',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6333494554484554480000000000000000000000000000000000000000000000',
      address: '0xfC62Ba94D8e400b1537c00393eF25c2187c901E4',
      leverage: 3,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '5X ETH ETH',
      type: '1',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6335455448455448000000000000000000000000000000000000000000000000',
      address: '0x01FDB108AAF2e93AdD6F8504c05492bBCa7D8Ee6',
      leverage: 5,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '5X Inverse ETH ETH',
      type: '0',
      tradeType: 'ETH',
      settleType: 'ETH',
      Bytes32: '0x6335494554484554480000000000000000000000000000000000000000000000',
      address: '0x49FF3e7DD2339488F6c57540F0006B543c82f63A',
      leverage: 5,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },

    {
      name: '1X UNI ETH',
      type: '1',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x6331554e49455448000000000000000000000000000000000000000000000000',
      address: '0xC8521f14F970E4bdD81e1Aa2A8A810b88Ae5Dda5',
      leverage: 1,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '1X Inverse UNI ETH',
      type: '0',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x633149554e494554480000000000000000000000000000000000000000000000',
      address: '0xcA56B39b52297e9c969cfd31D89ECe085139133B',
      leverage: 1,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    }, {
      name: '2X UNI ETH',
      type: '1',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x6332554e49455448000000000000000000000000000000000000000000000000',
      address: '0x9AD251667F1E04913b54092cfBd275655a06F582',
      leverage: 2,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '2X Inverse UNI ETH',
      type: '0',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x633249554e494554480000000000000000000000000000000000000000000000',
      address: '0x2FD021294222771C5040deE7d23894F00DB3c9a7',
      leverage: 2,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    }, {
      name: '3X UNI ETH',
      type: '1',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x6333554e49455448000000000000000000000000000000000000000000000000',
      address: '0x16bc60c60653Fe9d6290D191f700752491Fc3DdD',
      leverage: 3,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '3X Inverse UNI ETH',
      type: '0',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x633349554e494554480000000000000000000000000000000000000000000000',
      address: '0x3966b4b2a9F750D9813daEF8CaD85Ab50962A36a',
      leverage: 3,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    }, {
      name: '5X UNI ETH',
      type: '1',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x6335554e49455448000000000000000000000000000000000000000000000000',
      address: '0x275FCeF4BB3F8DD4EBB8849BccB59bB6194393f0',
      leverage: 5,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '5X Inverse UNI ETH',
      type: '0',
      tradeType: 'UNI',
      settleType: 'ETH',
      Bytes32: '0x633549554e494554480000000000000000000000000000000000000000000000',
      address: '0xC09A8FcDaC9249F0d87fBA461d7510e0320d345F',
      leverage: 5,
      settleToken: '0xd0a1e359811322d97991e03f863a0c30c2cf029c'
    },
    {
      name: '1X BTC TKN4',
      type: '1',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x6331425443544b4e340000000000000000000000000000000000000000000000',
      address: '0x8ef63C2955fc9aB2C6864C1E1bA94F4732F45B9F',
      leverage: 1,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '1X Inverse BTC TKN4',
      type: '0',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x633149425443544b4e3400000000000000000000000000000000000000000000',
      address: '0xa348a9808E4135E41e1011a8Bd5F61a32F17ea60',
      leverage: 1,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '2X BTC TKN4',
      type: '1',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x6332425443544b4e340000000000000000000000000000000000000000000000',
      address: '0xfB5E9920ee0228086a5514F1cE7E5c6f5b83E846',
      leverage: 2,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '2X Inverse BTC TKN4',
      type: '0',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x633249425443544b4e3400000000000000000000000000000000000000000000',
      address: '0x927cBE967D8AE0fb3Cf0d1E08D098b53ee847ADF',
      leverage: 2,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '5X BTC TKN4',
      type: '1',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x6335425443544b4e340000000000000000000000000000000000000000000000',
      address: '0x911C40bF4bCf0fb9211c2a8B3b09610656CA15CE',
      leverage: 5,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '5X Inverse BTC TKN4',
      type: '0',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x633549425443544b4e3400000000000000000000000000000000000000000000',
      address: '0x03AD2FCc26416614A84110E1D98cF1D00d5BeCC3',
      leverage: 5,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '10X BTC TKN4',
      type: '1',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x633130425443544b4e3400000000000000000000000000000000000000000000',
      address: '0xCD9ac5Df6687E53FC146292B406082c3Ac131aD4',
      leverage: 10,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '10X Inverse BTC TKN4',
      type: '0',
      tradeType: 'BTC',
      settleType: 'TKN4',
      Bytes32: '0x63313049425443544b4e34000000000000000000000000000000000000000000',
      address: '0xB236FdE2374b807C6e2Fe4C0799eD7bD8280bAFD',
      leverage: 10,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '1X ETH TKN4',
      type: '1',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x6331455448544b4e340000000000000000000000000000000000000000000000',
      address: '0x0b0Ce83f2224A0dAf530f493a45ADe82D9333fb9',
      leverage: 1,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '1X Inverse ETH TKN4',
      type: '0',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x633149455448544b4e3400000000000000000000000000000000000000000000',
      address: '0x10C8408D1F6156999B90802108EeD1e6275fE641',
      leverage: 1,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '2X ETH TKN4',
      type: '1',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x6332455448544b4e340000000000000000000000000000000000000000000000',
      address: '0x3ADdFC89F653977528b7f2e0F2380854CAC244D4',
      leverage: 2,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '2X Inverse ETH TKN4',
      type: '0',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x633249455448544b4e3400000000000000000000000000000000000000000000',
      address: '0xC0edd7B07B0f2EdA8Fd28AdEBc4A24d9B972Add2',
      leverage: 2,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '5X ETH TKN4',
      type: '1',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x6335455448544b4e340000000000000000000000000000000000000000000000',
      address: '0x3b2938f6B6FAe2691EfA74d10c216c75f3357B9B',
      leverage: 5,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '5X Inverse ETH TKN4',
      type: '0',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x633549455448544b4e3400000000000000000000000000000000000000000000',
      address: '0x84f7BFD22521C193fdb17bE8A0f35BF01D9B6c7c',
      leverage: 5,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '10X ETH TKN4',
      type: '1',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x633130455448544b4e3400000000000000000000000000000000000000000000',
      address: '0xDC800a7336f19416F9a79E99633167c97E3A262A',
      leverage: 10,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '10X Inverse ETH TKN4',
      type: '0',
      tradeType: 'ETH',
      settleType: 'TKN4',
      Bytes32: '0x63313049455448544b4e34000000000000000000000000000000000000000000',
      address: '0xf57eEcAaD6C4b37A05261c569C4D73f50172414A',
      leverage: 10,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '1X UNI TKN4',
      type: '1',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x6331554e49544b4e340000000000000000000000000000000000000000000000',
      address: '0x399A3F6fF246EeD838a6755E8f37D0EC9B391737',
      leverage: 1,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '1X Inverse UNI TKN4',
      type: '0',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x633149554e49544b4e3400000000000000000000000000000000000000000000',
      address: '0x01dd39531Aa5A814a2915170B26d957e76908469',
      leverage: 1,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '2X UNI TKN4',
      type: '1',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x6332554e49544b4e340000000000000000000000000000000000000000000000',
      address: '0xe06F52F1d2AE0018cf3f05a9701BB03d15bC4613',
      leverage: 2,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '2X Inverse UNI TKN4',
      type: '0',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x633249554e49544b4e3400000000000000000000000000000000000000000000',
      address: '0xbf76B6B197de28Eb851b646b6257C1d862085C7B',
      leverage: 2,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '5X UNI TKN4',
      type: '1',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x6335554e49544b4e340000000000000000000000000000000000000000000000',
      address: '0x011e6182D57687663FF8602b1eb0AC5Aa39fb2F8',
      leverage: 5,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '5X Inverse UNI TKN4',
      type: '0',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x633549554e49544b4e3400000000000000000000000000000000000000000000',
      address: '0x247C61F9E3Eb65df1905CD0cD5e71F68ae28Eb7f',
      leverage: 5,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    }, {
      name: '10X UNI TKN4',
      type: '1',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x633130554e49544b4e3400000000000000000000000000000000000000000000',
      address: '0xc377bC745d8E10cc70BcF37bc8E65446b1c540c2',
      leverage: 10,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '10X Inverse UNI TKN4',
      type: '0',
      tradeType: 'UNI',
      settleType: 'TKN4',
      Bytes32: '0x63313049554e49544b4e34000000000000000000000000000000000000000000',
      address: '0x4d9f0946Ed26D263D7f7f03F674EE2E638eDff92',
      leverage: 10,
      settleToken: '0xb39466b1282155b1251eab2bb0d5381b6eb66567'
    },
    {
      name: '1X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x6331425443434841524d00000000000000000000000000000000000000000000',
      address: '0x92d1057C8c0A2117e4ae238b9801c2bFD9eaf763',
      leverage: 1,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '1X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633149425443434841524d000000000000000000000000000000000000000000',
      address: '0x407a6397DF7f8FE005b595CcF44b9d5c7892525d',
      leverage: 1,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '5X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x6335425443434841524d00000000000000000000000000000000000000000000',
      address: '0x9A1052f8945E66fE1c96391Facc435EC53eaBFc8',
      leverage: 5,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '5X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633549425443434841524d000000000000000000000000000000000000000000',
      address: '0x6a7b3a6EF6157DA51C47CE977425b986F415aA24',
      leverage: 5,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '10X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
      address: '0x0F55625fe152f6fb6e3425F7833f490e0d6d9836',
      leverage: 10,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '10X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
      address: '0x5A5dC6AD582dBbaeAc0511F3F83aC8502AA0e905',
      leverage: 10,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '20X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
      address: '0xcafDcb944F8769ff33771929aA9A3dE66e0c5C2b',
      leverage: 20,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '20X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
      address: '0x76AaCe5d8CF7E6B4b3de07C879BEE5da99f62cb6',
      leverage: 20,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '1X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x6331455448434841524d00000000000000000000000000000000000000000000',
      address: '0x173fbb4c4Dd8861c48A27b83DE4932456f79Df17',
      leverage: 1,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '1X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633149455448434841524d000000000000000000000000000000000000000000',
      address: '0x2Db258e96cA564f5cbfb43291648192a5c1cF271',
      leverage: 1,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '5X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x6335455448434841524d00000000000000000000000000000000000000000000',
      address: '0xAdeBA04393c6A8BC218aA03a8Bb73EDa68cc4BB5',
      leverage: 5,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '5X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633549455448434841524d000000000000000000000000000000000000000000',
      address: '0xbebe05e26473244A6BA5B16EB75c44DB83F7729b',
      leverage: 5,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '10X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
      address: '0x1f1056605726daF732b091748CC1b95B13a2186D',
      leverage: 10,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '10X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
      address: '0xB826C75B0E1007c3dD8489807C62aD3992607CE9',
      leverage: 10,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '20X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
      address: '0x29309306d6Dc9c73083629cA7b4975956c45D8B5',
      leverage: 20,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '20X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
      address: '0x3579518e0b003bd94D04b2444ba2e77Cd22AD34a',
      leverage: 20,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '1X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x6331554e49434841524d00000000000000000000000000000000000000000000',
      address: '0x73bFF81121Fd05F6265Ad771f055119a74C2AA3A',
      leverage: 1,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '1X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633149554e49434841524d000000000000000000000000000000000000000000',
      address: '0x9BDa884b7B4C39BD39Ef413388Da2EB2aa604744',
      leverage: 1,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '5X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x6335554e49434841524d00000000000000000000000000000000000000000000',
      address: '0xe3Cc4eD62C80DCA986D3AA5497126cB7088F97A0',
      leverage: 5,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '5X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633549554e49434841524d000000000000000000000000000000000000000000',
      address: '0x573bB91622818815f234A74212ecf9e70218f192',
      leverage: 5,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '10X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633130554e49434841524d000000000000000000000000000000000000000000',
      address: '0xE9fc5BE17878f0F5E26EC3e631FA2dfc1aA9C35c',
      leverage: 10,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '10X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x63313049554e49434841524d0000000000000000000000000000000000000000',
      address: '0x441Ce30c899e0493ebC1Eb9f1B578147bcF91872',
      leverage: 10,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '20X UNI CHARM',
      type: '1',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x633230554e49434841524d000000000000000000000000000000000000000000',
      address: '0xBF68835154678923818EED393479578DeD934035',
      leverage: 20,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    },
    {
      name: '20X Inverse UNI CHARM',
      type: '0',
      tradeType: 'UNI',
      settleType: 'CHARM',
      Bytes32: '0x63323049554e49434841524d0000000000000000000000000000000000000000',
      address: '0x1C307559B707fb86E71018D481bc21E46648B0ae',
      leverage: 20,
      settleToken: '0x9c489570e85BAbaA4e5e534a4a6d9876d02Dc509'
    }

  ]
}
export default kovanNet;