<template>
  <v-app class="app-content">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
.app-content {
  width: 100%;
  height: 100%;
  background: #0A172B; //隐藏背景图的白边
  // background-image: url("./assets/bg.png");
  // background-size: cover;
}
</style>
