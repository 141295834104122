// 考虑到项目本身不大，也为了方便开发，这里映射的key值就直接使用原中文值，不再另外定义。
export default {
  交易: 'Trade', // 1.顶部菜单
  持仓: 'Position',
  行情: 'Quote',
  流动性: 'Pool',
  质押: 'Farm',
  分红币: 'Dividend',
  切换到旧版: 'Old version',
  聪期权: 'SATOSHI',
  操作教程: 'Tutorials',

  暂无数据: 'no Data',

  加载中: 'Loading',
  错误网络: 'Wrong Network',
  请选择钱包类型: 'Please select wallet type',
  连接: 'Connect',
  请先安装插件: 'Please install the plugin first',
  取消: 'Cancel',
  登出: 'Sign out',
  我的账户: 'My Account',

  牛证: 'Bull', // 2.交易
  熊证: 'Bear',
  标的资产: 'Underlying Asset',
  当前价格: 'Current Price',
  结算币: 'Settle Token',
  余额:'Balance',
  钱包余额: 'Balance',
  杠杆倍数: 'Leverage',
  开仓: 'Buy',
  平仓: 'Sell',
  交易成功: 'Success',
  在HECOINFO上查看: 'HECOINFO',
  在KOVANSCAN上查看: 'KOVANSCAN',
  在BSCSCAN上查看: 'BSCSCAN',

  '正在为您开仓，请耐心等待……': 'Trading for you, please wait patiently...',
  请先连接您的钱包: 'Please connect your wallet first',
  解锁钱包: ' Unlock wallet',

  您的持仓: 'Your Position', // 3.持仓
  同步数据: 'Synchronizing',
  '当本地持仓数据与链上数据不一致时，请点击此按钮进行同步': 'Click this button to synchronize as the local data may be inconsistent with the data on the chain',
  // 币种: 'Settle Token',
  类型: 'Type',
  详情: 'Details',
  权证数量: 'Contract Shares',
  权证价格: 'Contract Price',
  开仓数额: 'Amount',
  平仓比例: 'Proportion',
  平仓数量: 'Shares',
  开仓成本: 'Cost',
  持仓数额: 'Amount',
  标的现价: 'Current Underlying Price',
  开仓均价: 'Average Purchase Price',
  收益率: 'Return',
  rebase价格: 'Rebase Underlying Price',
  '价格在0~2之间变化': 'Price varies from  0 to 2',
  权证价格重置为初始值1: 'reset contract price to the initial value of 1',
  最大: 'Max',
  收起筛选: 'Hide filter',
  开始筛选: 'Filter',
  开仓时间: 'Created',

  历史价格: 'Historical Price', // 4.行情
  标的资产价格: 'Underlying Asset Price',
  操作: 'Operation',
  价格: 'Price',
  最近1小时: '1 Hour',
  最近24小时: '24 Hours',
  最近3天: '3 Days',
  全部: 'All',
  时间: 'Date',
  交易所: 'Exchange',
  市值: 'Market value',

  '提供流动性，挖矿平台币': 'Provide liquidity to mine CHARM', // 5.流动性
  提供流动性: '提供流动性', // 5.流动性
  挖矿平台币: '挖矿平台币', // 5.流动性
  持有流动性数量: 'Your Position',
  预估对应结算币数量: '(expected)Pooled Settle Token',
  移除比例: 'Proportion',
  预计获得流动性份额: 'You’ll receive',
  批准: 'Approve',
  确定: 'Confirm',
  结算币总量: 'Settle Token',

  收益: 'Rewards', // 6.质押
  可获取: 'Available',
  抵押: 'Deposit',
  取消抵押: 'Withdraw',
  获取: 'Harvest',
  质押2: 'DEPOSIT',
  持有数量: 'Your Position',
  未质押: 'Undeposited',
  已质押: 'Deposited',
  天: 'day',
  取消质押: 'Withdraw',
  先添加流动性: 'Add liquidity first',

  提供平台币挖矿: 'Stake CPPC to mine more CPPC', // 7.二级抵押
  抵押CPPC: 'Stake CPPC',
  分红币数量: 'Amount',

  兑换: 'Mint',
  销毁: 'Burn',
  分红币占比: 'Your Pool Share',
  池子可获取利润: 'Claimable Profits in Pool',

  邮箱: 'Email', // 其他
  需先提供流动性再质押: 'Liquidity shall be provided before pledge',
  牛熊证:'CBBC',
  开仓数量:'Amount',
  标的物:'Asset',
  做多:'long',
  做空:'short',
  // 挖矿
  质押挖矿:'Farm',
  质押APR:'APR-Stake APR',
  质押TVL:'TVL-Stake TVL',
  质押收益: 'Stake Award',
  质押获取:'Stake Harvest',
  添加质押:'DEPOSIT',
  移除质押:'WITHDRAW',
  添加流动性:'Add liquidity',
  移除流动性:'Remove liquidity',
  总开仓量:'Total Open Interests',
  平台分红:'Dividend',
  流动性总量:'Total Supply',
  持有流动性代币:'Holding liquidity tokens',
  流动性占比:'Your Pool Share',
  分红币总量:'Total Supply',
  可获取利润:'Claimable Profits in pool',
  将CHARM置换成分红币:'mint',
  销毁分红币获取分红:'burn'
}
