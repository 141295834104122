import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/Layout.vue'

Vue.use(VueRouter)

// 解决跳转当前页，控制台抛错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/trade',
    children: [
      {
        path: '/trade',
        name: 'Trade',
        component: () => import('../views/Trade.vue')
      },
      {
        path: '/mineLiquidityDivid',
        name: 'MineLiquidityDivid',
        component: () => import('../views/MineLiquidityDivid.vue')
      },
      {
        path: '/satoshi',
        beforeEnter () { window.open('https://satoshi.charm.exchange') }
      },
      {
        path: '/powerswap',
        beforeEnter () { window.open('https://powerswap.charm.exchange/') }
      },
      // {
      //   path: '/tutorial',
      //   component: () => import('../views/tutorial.vue')
      // },
      {
        path: '/quotes',
        name: 'Quotes',
        component: () => import('../views/quotes.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
