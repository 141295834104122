import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
var store = new Vuex.Store({
  state: {
    currentNetworkConfig: null, // 当前连接网络的各项config
    defaultAccount: '',
    defaultChainId: 0, // 当前连接网络 id
    isLogin: false,
    currentEth: 0,
    pendingStatus: false,
    isMobileBol: false,// 是否是移动端
    proOrLite:'lite' // 记录当前是PRO或者LITE
  },
  actions:{
    setCurProOrLite({commit}, data) {
      commit('updateProOrLite',data)
    }
  },
  mutations: {
    upDateIsMobileBol (state, bol) {
      state.isMobileBol = bol
    },
    upDateDefaultAccount (state, account) {
      state.defaultAccount = account
    },
    upDateIsLogin (state, bol) {
      state.isLogin = bol
    },
    upDateDefaultChainId (state, chainId) {
      state.defaultChainId = chainId
    },
    upCurrentNetworkConfig (state, currentNetworkConfig) {
      state.currentNetworkConfig = currentNetworkConfig
    },
    upCurrentEth (state, amount) {
      state.currentEth = amount
    },
    upPendingStatus (state, bol) {
      state.pendingStatus = bol
    },
    updateProOrLite(state, payload) {
      state.proOrLite = payload
    }
  }
})

export default store
