import Vue from 'vue'

// treeshaking 方式引入，减小打包体积
// https://vuetifyjs.com/zh-Hans/features/treeshaking/#vuetify-loader
import Vuetify from 'vuetify/lib'

import '@mdi/font/css/materialdesignicons.css'// 字体图标

/* 关于国际化的说明：
* 国际化分为三部分
* 1.Vuetify基础组件的国际化，比如日期选择器，分页等，这里不需要做处理，直接用它本身的语言映射就可以了，当然也可以覆盖重写它。
* 2.项目本身的静态文本，比如开仓，持仓等，这里需要将这些文本翻译成对应语言的映射（lang文件夹下）。考虑到项目本身不大，也为了方便开发，这里映射的key值就直接使用原中文值，不再另外定义。
* 3.接口返回信息的国际化（暂不用考虑）
* 注：更强大的国际化，可考虑引入vue-i18n
*/

// vuetify基础UI组件自带的语言映射
import vuetifyEn from 'vuetify/lib/locale/en'
import vuetifyZhHans from 'vuetify/lib/locale/zh-Hans'

// 项目内的静态文本语言映射
import localEn from '../lang/en'
import localZhHans from '../lang/zh-Hans'
Vue.use(Vuetify)

const locales = { // 所有的语言映射
  en: { // 英文
    ...vuetifyEn,
    ...localEn
  },
  zhHans: { // 简体中文
    ...vuetifyZhHans,
    ...localZhHans
  }
  // ...其他语言扩展
}

// 默认语言 zhHans
let language = 'zhHans'
if (localStorage.getItem('language')) {
  language = localStorage.getItem('language')
} else {
  localStorage.setItem('language', 'zhHans')
  language = 'zhHans'
}

export default new Vuetify({
  lang: {
    locales,
    current: language// 当前使用的语言
  },
  theme: {
    themes: {
      light: {
        background: '#FFFFF0',
        btnColor: '#424D5F',
        btnColor1: '#FFFFCC',
        btnHoverColor: '#f7f4f2',
        btnTextColor: '#6da8ff',
        textColor: '#6da8ff',
        layerBtn: '#f0e9e7',
        btnRed: '#ff0000',
        btnGreen: '#008000',
        btnP: '#FFFFCC',
        btnW: '#F44336',
        bullColor: '#FF6871',
        bearColor: '#0483FF',
        white: '#c3c5cb',
        textBlue: '#6DA8FF',
        bearButtonTrade: '#6da8ff',
        bullButtonTrade: '#e359e1',
        darkBlue: '#1e90ff',
        darkPink: '#e359e1',
        darkBlueBackground: '#224a6d',
        darkPinkBackground: '#4a2f4e',
        dialogBackground: '#232429',
        dialogInsideBox: '#292929',
        walletBackground: '#2E3442'
      }
    }
  },
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  }
})
