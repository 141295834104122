
// bsc网络
const bscNet = {
  chainId: 56,
  netUrl: 'https://bscscan.com',
  name: 'Binance Smart Chain Mainnet',
  shortName: 'bsc',
  chain: 'Bsc',
  unit: 'BNB',
  networkName: 'BSC',
  tradeSuccessTips: '在BSCSCAN上查看',
  tradeType: ['BTC', 'ETH'],
  liquidityTokenAddress: { //  结算币  流动性的address
    BNB: '0xDA6C19Ce10BaAC6238Fb9546eb1180A3ec92d563',
    USDT: '0x4dBa21Db68e00079f92CfC9D1BaA623d25B1E1fc',
    CHARM: '0x706789b6bb7fAC97A57F743dD761642c39954836',
    CNYT: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961',
    'BNB/CHARM': '0xfDDD0209C45BA3BC8c7502D025179B5cB4c3b102', // 从powerswap过来的质押币
    'USDT/CHARM': '0x1b96eB61e93a006eDA933B780aDe5909ca708762' // 从powerswap过来的质押币
  },
  tradeTokenList: [// 当前网络下 标的物类型
    { Bytes32: '0x4254430000000000000000000000000000000000000000000000000000000000', name: 'BTC' },
    { Bytes32: '0x4554480000000000000000000000000000000000000000000000000000000000', name: 'ETH' }
  ],
  settleTokenList: [// 结算币种类
    {
      address: '0x55d398326f99059ff775485246999027b3197955',
      liquidityTokenAddr: '0x4dBa21Db68e00079f92CfC9D1BaA623d25B1E1fc',
      Bytes32: '0x5553445400000000000000000000000000000000000000000000000000000000',
      name: 'USDT',
      logoName: 'USDT.png'// 前端逻辑用
    },
    {
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      liquidityTokenAddr: '0xDA6C19Ce10BaAC6238Fb9546eb1180A3ec92d563',
      Bytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      name: 'BNB',
      logoName: 'BNB.png'// 前端逻辑用
    },
    {
      address: '0x706789b6bb7fAC97A57F743dD761642c39954836',
      liquidityTokenAddr: '0x706789b6bb7fAC97A57F743dD761642c39954836',
      Bytes32: '0x434841524d000000000000000000000000000000000000000000000000000000',
      name: 'CHARM',
      logoName: 'CHARM.png'// 前端逻辑用
    },
    //zhh 20121208
    //结算币
    {

      address: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961',
      liquidityTokenAddr: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961',
      //Bytes32: '0x434e595400000000000000000000000000000000000000000000000000000000',
      Bytes32: '0x75434e5954000000000000000000000000000000000000000000000000000000',
      name: 'CNYT',
      logoName: 'CNYT.png'

    },
    {

      address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      liquidityTokenAddr: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      Bytes32: '0x43414b4500000000000000000000000000000000000000000000000000000000',
      name: 'CAKE',
      logoName: 'CAKE.png'

    },
    {

      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      liquidityTokenAddr: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      Bytes32: '0x41544f4b454e3200000000000000000000000000000000000000000000000000',
      name: 'BUSD',
      logoName: 'BUSD.png'

    },
    // {

    //   address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    //   liquidityTokenAddr: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    //   Bytes32: '0x4d41544943000000000000000000000000000000000000000000000000000000',
    //   name: 'MATIC',
    //   logoName: 'MATIC.png'

    // },
    // {

    //   address: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   liquidityTokenAddr: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    //   Bytes32: '0x4d44580000000000000000000000000000000000000000000000000000000000',
    //   name: 'MDX',
    //   logoName: 'MDX.png'

    // },

    //end

  ],
  powerswapSettleTokenList: [// 从powerswap过来的质押币
    {
      name: 'BNB/CHARM',
      Bytes32: '0x424e422f434841524d0000000000000000000000000000000000000000000000',
      address: '0xfDDD0209C45BA3BC8c7502D025179B5cB4c3b102',
      liquidityTokenAddr: '0xfDDD0209C45BA3BC8c7502D025179B5cB4c3b102',
      logoName: 'ETH-CHARM.png', // 前端逻辑用
      isPowerswap: true// 前端逻辑用
    },
    {
      name: 'USDT/CHARM',
      Bytes32: '0x555344542f434841524d00000000000000000000000000000000000000000000',
      address: '0x1b96eB61e93a006eDA933B780aDe5909ca708762',
      liquidityTokenAddr: '0x1b96eB61e93a006eDA933B780aDe5909ca708762',
      logoName: 'USDT-CHARM.png', // 前端逻辑用
      isPowerswap: true// 前端逻辑用
    }
  ],
  tradeLiquiditySettleTokenList: [// 能开仓的流动币类型
    {
      address: '0x4dBa21Db68e00079f92CfC9D1BaA623d25B1E1fc',
      liquidityTokenAddr: '0x4dBa21Db68e00079f92CfC9D1BaA623d25B1E1fc',
      Bytes32: '0x6c55534454000000000000000000000000000000000000000000000000000000',
      settleBytes32: '0x5553445400000000000000000000000000000000000000000000000000000000',
      isLiquidity: true,
      name: 'lUSDT'
    },
    {
      address: '0xDA6C19Ce10BaAC6238Fb9546eb1180A3ec92d563',
      liquidityTokenAddr: '0xDA6C19Ce10BaAC6238Fb9546eb1180A3ec92d563',
      Bytes32: '0x6c48540000000000000000000000000000000000000000000000000000000000',
      settleBytes32: '0x4554480000000000000000000000000000000000000000000000000000000000',
      isLiquidity: true,
      name: 'lBNB'
    }
  ],
  dividendSettleTokenList: [// 分红币币种类
    {
      address: '0x3b56550937712FF5A54E78858846Af1bAEb656a7',
      settleTokenAddr: '0x55d398326f99059ff775485246999027b3197955',
      Bytes32: '0x6455534454000000000000000000000000000000000000000000000000000000',
      name: 'dUSDT'
    },
    {
      address: '0xA277f0707355FA2F86d98a5B5C09C7919b7f859D',
      settleTokenAddr: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      Bytes32: '0x6448540000000000000000000000000000000000000000000000000000000000',
      name: 'dBNB'
    }
  ],
  addressBook: { // 各种 address(实例化各类abi合约)
    SystemSettingsAddress: '0x75586dEAF8703Bd58399b11Ca3aA4FbF2808F45E',
    cbbcRouterAddress: '0x9399F216c56650668e6799A1F18D1814BE743479',
    orchestratorAddress: '0x12812d4eE904Ef9f5A49aAe24Be4A4ccFBC5a06d',
    marketOracleAddress: '0xDe3c1790464D0efcd5317F7c2D8241270f1c641E',

    wethAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', // 注：就是结算币ETH的address（heco下就是HT的address）
    addressResolver: '0x53D9ec3B50069C9d737D5296A34261CC4750122F', // readProxy

    cppcChefAddress: '0xFc715C2d58D84fFCD655ec539cCAdEa82A719D20',
    IssuerForCbbcTokenAddress: '0xE07329E4Ba0De3eDEE5Ec1a744a2709Dc15492F9',

    //zhh 20221208
    routerV2Address: '0x9f054346041F41568f1c4AE7229ED9C39198Ed8A',

    //end
  },
  cbbcType: [// 各种类型权证信息
    {
      name: '1X BTC BNB', // 名称：杠杆倍数 - 牛证还是熊证 - 标的物 - 结算币
      type: '1', // 1是牛证。0是熊证
      tradeType: 'BTC', // 标的物种类
      settleType: 'BNB', // 结算币种类
      Bytes32: '0x6331425443424e42000000000000000000000000000000000000000000000000', // 权证的Bytes32
      address: '0x91BfC7F1bce87Eb6Bf9F603610f49B87EE6f717B', // 权证的address
      leverage: 1, // 杠杆倍数
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'// 对应结算币的address
    },
    {
      name: '1X Inverse BTC BNB',
      type: '0',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x633149425443424e420000000000000000000000000000000000000000000000',
      address: '0xA1c6f7791FB722FD9769A6c3E79F2166a52d00b6',
      leverage: 1,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '2X BTC BNB',
      type: '1',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x6332425443424e42000000000000000000000000000000000000000000000000',
      address: '0xd2438703a83C4859490D1c259c3E11995553Ba12',
      leverage: 2,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '2X Inverse BTC BNB',
      type: '0',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x633249425443424e420000000000000000000000000000000000000000000000',
      address: '0xE6f7F8Cb47c4404AfDe2BFBfeF2bbEC9ffFC2c14',
      leverage: 2,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '3X BTC BNB',
      type: '1',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x6333425443424e42000000000000000000000000000000000000000000000000',
      address: '0xb3cB953D2E113B7Dc7aA0E4a1718F0f60d7e516B',
      leverage: 3,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '3X Inverse BTC BNB',
      type: '0',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x633349425443424e420000000000000000000000000000000000000000000000',
      address: '0x3B2D31A549890fE8615F9E913f70D78f362cd09B',
      leverage: 3,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '5X BTC BNB',
      type: '1',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x6335425443424e42000000000000000000000000000000000000000000000000',
      address: '0x70340348B8f4a488b4fD9c1c29E912F8a1BbA7a4',
      leverage: 5,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '5X Inverse BTC BNB',
      type: '0',
      tradeType: 'BTC',
      settleType: 'BNB',
      Bytes32: '0x633549425443424e420000000000000000000000000000000000000000000000',
      address: '0xeD074c5A653167086B27a52aA979532E50c25500',
      leverage: 5,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '1X ETH BNB',
      type: '1',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x6331455448424e42000000000000000000000000000000000000000000000000',
      address: '0x13E06f748Dd900412F88A363Fb9006226EB75DAa',
      leverage: 1,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '1X Inverse ETH BNB',
      type: '0',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x633149455448424e420000000000000000000000000000000000000000000000',
      address: '0x266C80f102d9cdB57823F7fF51bC11eC1719fD50',
      leverage: 1,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '2X ETH BNB',
      type: '1',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x6332455448424e42000000000000000000000000000000000000000000000000',
      address: '0x64cAc64012D622Ad1FbA3412aa9874c1bf4ed7A5',
      leverage: 2,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '2X Inverse ETH BNB',
      type: '0',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x633249455448424e420000000000000000000000000000000000000000000000',
      address: '0x304E2D9F1081A5D959EABd4Fe81e42533644683A',
      leverage: 2,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '3X ETH BNB',
      type: '1',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x6333455448424e42000000000000000000000000000000000000000000000000',
      address: '0x8643aeBe5f0d1b941128Ff6f28E9AD3Ddd2dC745',
      leverage: 3,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '3X Inverse ETH BNB',
      type: '0',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x633349455448424e420000000000000000000000000000000000000000000000',
      address: '0xe119D3413D42c1947c77bd6e4eb4B7C85970e246',
      leverage: 3,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '5X ETH BNB',
      type: '1',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x6335455448424e42000000000000000000000000000000000000000000000000',
      address: '0x9Cc1e1a575c9bc8816e98b0e51A054662FB0aF7A',
      leverage: 5,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '5X Inverse ETH BNB',
      type: '0',
      tradeType: 'ETH',
      settleType: 'BNB',
      Bytes32: '0x633549455448424e420000000000000000000000000000000000000000000000',
      address: '0x109774157703Fb3DEA6A89b5031B6B7667d66158',
      leverage: 5,
      settleToken: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
    },
    {
      name: '1X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331425443555344540000000000000000000000000000000000000000000000',
      address: '0xdAdd8CCbb8f9662796E387cedD1382766D830c61',
      leverage: 1,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '1X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331494254435553445400000000000000000000000000000000000000000000',
      address: '0xc5d55343bF60F2345a796d244F755805117Abc88',
      leverage: 1,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '2X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6332425443555344540000000000000000000000000000000000000000000000',
      address: '0x40425EB7f790F09781636E98520c12a32684d7Cb',
      leverage: 2,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '2X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6332494254435553445400000000000000000000000000000000000000000000',
      address: '0xDF7C8719A689B0A1bB88Fc73f732c0Cf26F566a5',
      leverage: 2,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '5X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6335425443555344540000000000000000000000000000000000000000000000',
      address: '0xfEbcb8EB037dDc089384c1355D67C2c89a6CC287',
      leverage: 5,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '5X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6335494254435553445400000000000000000000000000000000000000000000',
      address: '0x5F41216820AE0dF53E41Ab42D5E74fd4c5E3ECE1',
      leverage: 5,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '10X BTC USDT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331304254435553445400000000000000000000000000000000000000000000',
      address: '0x01b20e57F5f862E0C511D94a91337294d29cAae1',
      leverage: 10,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '10X Inverse BTC USDT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'USDT',
      Bytes32: '0x6331304942544355534454000000000000000000000000000000000000000000',
      address: '0x7748f4865C0ab424623BA10A815E1eDA80e98C87',
      leverage: 10,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '1X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331455448555344540000000000000000000000000000000000000000000000',
      address: '0x742EA60aad40591D63b09Aec85D19e8Dd5d91426',
      leverage: 1,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '1X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331494554485553445400000000000000000000000000000000000000000000',
      address: '0x80633cCbEe2eFbD2ac8C893239617701f98b0BB0',
      leverage: 1,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '2X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6332455448555344540000000000000000000000000000000000000000000000',
      address: '0x0eddB302AB51989f3cabf57ae5c4668e9c05c35C',
      leverage: 2,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '2X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6332494554485553445400000000000000000000000000000000000000000000',
      address: '0xefD5EC6A29987297cD28C06c1cDC586f6a71B32c',
      leverage: 2,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '5X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6335455448555344540000000000000000000000000000000000000000000000',
      address: '0x8bf2051B9Dc435E77A8a149A3e80b2281b5CA915',
      leverage: 5,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '5X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6335494554485553445400000000000000000000000000000000000000000000',
      address: '0x24444388918b463e08570f75a13155C92D8A03eA',
      leverage: 5,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '10X ETH USDT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331304554485553445400000000000000000000000000000000000000000000',
      address: '0xBC0e14C9aA4554ECB1BA1E4a08C3fb95FcAAdd6f',
      leverage: 10,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '10X Inverse ETH USDT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'USDT',
      Bytes32: '0x6331304945544855534454000000000000000000000000000000000000000000',
      address: '0xf0eF354b9cC38D4DEBb2F0C9A4d81e9d7798E366',
      leverage: 10,
      settleToken: '0x55d398326f99059ff775485246999027b3197955'
    },
    {
      name: '1X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x6331425443434841524d00000000000000000000000000000000000000000000',
      address: '0xa003571CEe4907B1F6f3A783d985f38fd4C8c190',
      leverage: 1,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '1X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633149425443434841524d000000000000000000000000000000000000000000',
      address: '0xdace98AE6745820688eF7E0f179a398E002fe800',
      leverage: 1,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '5X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x6335425443434841524d00000000000000000000000000000000000000000000',
      address: '0xE9fE959C4097dE9e2f137Ad5b364cd482Da13521',
      leverage: 5,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '5X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633549425443434841524d000000000000000000000000000000000000000000',
      address: '0xB4d72f97d165F72eED012Ea0E47AAeBFA88134f5',
      leverage: 5,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    //zhh 20221216 charm remain 1,5 leverage
    {
      name: '10X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
      address: '0xC867BA408A1Bf8b0b904Ae6b7e46336b1F957797',
      leverage: 10,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '10X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
      address: '0x3b80785Da706429d6b5F2149dEE404eE823C3cB0',
      leverage: 10,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '20X BTC CHARM',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
      address: '0x4839F00547E4B8aac4F7651Ae79656AD03D8729E',
      leverage: 20,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '20X Inverse BTC CHARM',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CHARM',
      Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
      address: '0x11765170a448ef312E3bF2A841f23d020b179C55',
      leverage: 20,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '1X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x6331455448434841524d00000000000000000000000000000000000000000000',
      address: '0xEF4C77b5B2D7F0c731Dfa805c4A6D934a96d1a8C',
      leverage: 1,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '1X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633149455448434841524d000000000000000000000000000000000000000000',
      address: '0x1FB9E81DeF7999247b289a67D9D8A1e98b61EdbB',
      leverage: 1,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '5X ETH CHARM',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x6335455448434841524d00000000000000000000000000000000000000000000',
      address: '0xF1fFEdb7ADc7721aA4c44eC0B621D275059ecC9d',
      leverage: 5,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    {
      name: '5X Inverse ETH CHARM',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CHARM',
      Bytes32: '0x633549455448434841524d000000000000000000000000000000000000000000',
      address: '0x59962148cFB82507D309947775E3aA9f05fDc12C',
      leverage: 5,
      settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    },
    // charm remain 1, 5 leverage
    // {
    //   name: '10X ETH CHARM',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'CHARM',
    //   Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
    //   address: '0xa54EC9a2fc02706A7E1c346983291B3707a2046d',
    //   leverage: 10,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    // {
    //   name: '10X Inverse ETH CHARM',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'CHARM',
    //   Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xC8271BC72c8C1f1eC850cEE8eD4e3ef70d9D4Eb2',
    //   leverage: 10,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    // {
    //   name: '20X ETH CHARM',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'CHARM',
    //   Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x1e71A83ff14A157fb8816D3C51f5Eb8853b2a47B',
    //   leverage: 20,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    // {
    //   name: '20X Inverse ETH CHARM',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'CHARM',
    //   Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0x415f0f0775ea3CeD281F154DFee8f8c4A95fFb59',
    //   leverage: 20,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    //zhh 20221208
    {
      name: '100X BTC CNYT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x6331303042544375434e59540000000000000000000000000000000000000000',
      address: '0x4D040f184436aD44Ad377207e6302AAEdc3f2E28',
      leverage: 100,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '100X Inverse BTC CNYT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x633130304942544375434e595400000000000000000000000000000000000000',
      address: '0x0b41981AB228F84Deb9232bb669698e1C8f7F2d9',
      leverage: 100,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '150X BTC CNYT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x6331353042544375434e59540000000000000000000000000000000000000000',
      address: '0x3D8a8e343D6EC8ACF821f2c5788Acd2271EE690F',
      leverage: 150,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '150X Inverse BTC CNYT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x633135304942544375434e595400000000000000000000000000000000000000',
      address: '0x23486E4CE6a452cD9C56802b4274a0290B51522c',
      leverage: 150,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '200X BTC CNYT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x6332303042544375434e59540000000000000000000000000000000000000000',
      address: '0xAf91214B39A23dbAA828DAD7edcA352d90bc18b1',
      leverage: 200,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '200X Inverse BTC CNYT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x633230304942544375434e595400000000000000000000000000000000000000',
      address: '0xCD30EFeb22da625097c3E2b74fcB7D3DDa010ae2',
      leverage: 200,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '250X BTC CNYT',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x6332353042544375434e59540000000000000000000000000000000000000000',
      address: '0x51bf67A198e73a64e81a9B5999E9CFcF40a3a635',
      leverage: 250,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '250X Inverse BTC CNYT',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CNYT',
      Bytes32: '0x633235304942544375434e595400000000000000000000000000000000000000',
      address: '0x6b02Cf8557ab43487735463990191B58BA64f6E7',
      leverage: 250,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    // {
    //   name: '5X BTC CAKE',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'CAKE',
    //   Bytes32: '0x6335425443434841524d00000000000000000000000000000000000000000000',
    //   address: '0xE9fE959C4097dE9e2f137Ad5b364cd482Da13521',
    //   leverage: 5,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '5X Inverse BTC CAKE',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'CAKE',
    //   Bytes32: '0x633549425443434841524d000000000000000000000000000000000000000000',
    //   address: '0xB4d72f97d165F72eED012Ea0E47AAeBFA88134f5',
    //   leverage: 5,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '10X BTC CAKE',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'CAKE',
    //   Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
    //   address: '0xC867BA408A1Bf8b0b904Ae6b7e46336b1F957797',
    //   leverage: 10,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '10X Inverse BTC CAKE',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'CAKE',
    //   Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x3b80785Da706429d6b5F2149dEE404eE823C3cB0',
    //   leverage: 10,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    {
      name: '20X BTC CAKE',
      type: '1',
      tradeType: 'BTC',
      settleType: 'CAKE',
      Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
      address: '0x4839F00547E4B8aac4F7651Ae79656AD03D8729E',
      leverage: 20,
      settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    },
    {
      name: '20X Inverse BTC CAKE',
      type: '0',
      tradeType: 'BTC',
      settleType: 'CAKE',
      Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
      address: '0x11765170a448ef312E3bF2A841f23d020b179C55',
      leverage: 20,
      settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    },
    // {
    //   name: '50X BTC CAKE',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'CAKE',
    //   Bytes32: '0x633530425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x436a0F63F2b002429b46a405ec916940Fb2B6f6b',
    //   leverage: 50,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '50X Inverse BTC CAKE',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'CAKE',
    //   Bytes32: '0x63353049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x1f501c1c0239aB5Fe721bc73d3292AD2EAc25fE1',
    //   leverage: 50,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '5X BTC MATIC',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x6335425443434841524d00000000000000000000000000000000000000000000',
    //   address: '0xE9fE959C4097dE9e2f137Ad5b364cd482Da13521',
    //   leverage: 5,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '5X Inverse BTC MATIC',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633549425443434841524d000000000000000000000000000000000000000000',
    //   address: '0xB4d72f97d165F72eED012Ea0E47AAeBFA88134f5',
    //   leverage: 5,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '10X BTC MATIC',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
    //   address: '0xC867BA408A1Bf8b0b904Ae6b7e46336b1F957797',
    //   leverage: 10,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '10X Inverse BTC MATIC',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x3b80785Da706429d6b5F2149dEE404eE823C3cB0',
    //   leverage: 10,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '20X BTC MATIC',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x4839F00547E4B8aac4F7651Ae79656AD03D8729E',
    //   leverage: 20,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '20X Inverse BTC MATIC',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x11765170a448ef312E3bF2A841f23d020b179C55',
    //   leverage: 20,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '50X BTC MATIC',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633530425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x436a0F63F2b002429b46a405ec916940Fb2B6f6b',
    //   leverage: 50,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '50X Inverse BTC MATIC',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MATIC',
    //   Bytes32: '0x63353049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x1f501c1c0239aB5Fe721bc73d3292AD2EAc25fE1',
    //   leverage: 50,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '5X BTC MDX',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x6335425443434841524d00000000000000000000000000000000000000000000',
    //   address: '0xE9fE959C4097dE9e2f137Ad5b364cd482Da13521',
    //   leverage: 5,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '5X Inverse BTC MDX',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x633549425443434841524d000000000000000000000000000000000000000000',
    //   address: '0xB4d72f97d165F72eED012Ea0E47AAeBFA88134f5',
    //   leverage: 5,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '10X BTC MDX',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
    //   address: '0xC867BA408A1Bf8b0b904Ae6b7e46336b1F957797',
    //   leverage: 10,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '10X Inverse BTC MDX',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x3b80785Da706429d6b5F2149dEE404eE823C3cB0',
    //   leverage: 10,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '20X BTC MDX',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x4839F00547E4B8aac4F7651Ae79656AD03D8729E',
    //   leverage: 20,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '20X Inverse BTC MDX',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x11765170a448ef312E3bF2A841f23d020b179C55',
    //   leverage: 20,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '50X BTC MDX',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x633530425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x436a0F63F2b002429b46a405ec916940Fb2B6f6b',
    //   leverage: 50,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '50X Inverse BTC MDX',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'MDX',
    //   Bytes32: '0x63353049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x1f501c1c0239aB5Fe721bc73d3292AD2EAc25fE1',
    //   leverage: 50,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '5X BTC BUSD',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'BUSD',
    //   Bytes32: '0x6335425443425553440000000000000000000000000000000000000000000000',
    //   address: '0xD7E7D2441AD77c5c6aB49040c3F9c5526489c0aA',
    //   leverage: 5,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '5X Inverse BTC BUSD',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'BUSD',
    //   Bytes32: '0x6335494254434255534400000000000000000000000000000000000000000000',
    //   address: '0x951BbcC7CeF9603865d70cdDEEBfdcCF2B5f7AE8',
    //   leverage: 5,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    {
      name: '10X BTC BUSD',
      type: '1',
      tradeType: 'BTC',
      settleType: 'BUSD',
      Bytes32: '0x633130425443434841524d000000000000000000000000000000000000000000',
      address: '0xC867BA408A1Bf8b0b904Ae6b7e46336b1F957797',
      leverage: 10,
      settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    },
    {
      name: '10X Inverse BTC BUSD',
      type: '0',
      tradeType: 'BTC',
      settleType: 'BUSD',
      Bytes32: '0x63313049425443434841524d0000000000000000000000000000000000000000',
      address: '0x3b80785Da706429d6b5F2149dEE404eE823C3cB0',
      leverage: 10,
      settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    },
    // {
    //   name: '20X BTC BUSD',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'BUSD',
    //   Bytes32: '0x633230425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x4839F00547E4B8aac4F7651Ae79656AD03D8729E',
    //   leverage: 20,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '20X Inverse BTC BUSD',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'BUSD',
    //   Bytes32: '0x63323049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x11765170a448ef312E3bF2A841f23d020b179C55',
    //   leverage: 20,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '50X BTC BUSD',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'BUSD',
    //   Bytes32: '0x633530425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x436a0F63F2b002429b46a405ec916940Fb2B6f6b',
    //   leverage: 50,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '50X Inverse BTC BUSD',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'BUSD',
    //   Bytes32: '0x63353049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x1f501c1c0239aB5Fe721bc73d3292AD2EAc25fE1',
    //   leverage: 50,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '50X BTC CHARM',
    //   type: '1',
    //   tradeType: 'BTC',
    //   settleType: 'CHARM',
    //   Bytes32: '0x633530425443434841524d000000000000000000000000000000000000000000',
    //   address: '0x436a0F63F2b002429b46a405ec916940Fb2B6f6b',
    //   leverage: 50,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    // {
    //   name: '50X Inverse BTC CHARM',
    //   type: '0',
    //   tradeType: 'BTC',
    //   settleType: 'CHARM',
    //   Bytes32: '0x63353049425443434841524d0000000000000000000000000000000000000000',
    //   address: '0x1f501c1c0239aB5Fe721bc73d3292AD2EAc25fE1',
    //   leverage: 50,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    {
      name: '100X ETH CNYT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x6331303045544875434e59540000000000000000000000000000000000000000',
      address: '0xb8B98FCDf2249057cEF6FDE355E38352f126a95A',
      leverage: 100,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '100X Inverse ETH CNYT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x633130304945544875434e595400000000000000000000000000000000000000',
      address: '0xca1D4AB8f94Cb900DC646a9C33BE60eec3F4A667',
      leverage: 100,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '150X ETH CNYT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x6331353045544875434e59540000000000000000000000000000000000000000',
      address: '0x0Fcde01bF4abB24b77797141827BB4D71cf03095',
      leverage: 150,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '150X Inverse ETH CNYT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x633135304945544875434e595400000000000000000000000000000000000000',
      address: '0x37C486d26a1C7e2DDBF29e849ffd72d208eC167A',
      leverage: 150,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '200X ETH CNYT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x6332303045544875434e59540000000000000000000000000000000000000000',
      address: '0x2302AD11E37829a18c6124734FA9D168b7EeAc66',
      leverage: 200,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '200X Inverse ETH CNYT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x633230304945544875434e595400000000000000000000000000000000000000',
      address: '0xCe637E84F2987361a4038b0e70Fd2b35689e2523',
      leverage: 200,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '250X ETH CNYT',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x6332353045544875434e59540000000000000000000000000000000000000000',
      address: '0x9f01923b67AF3cB5cdeC1ab989E9D7b60Bb31c4f',
      leverage: 250,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    {
      name: '250X Inverse ETH CNYT',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CNYT',
      Bytes32: '0x633235304945544875434e595400000000000000000000000000000000000000',
      address: '0xFb491aC96FF69795dc98BAb22B87F09D349575a5',
      leverage: 250,
      settleToken: '0x10a1664830B4cE7cD6F5cFA5Ff24da6B8A776961'
    },
    // {
    //   name: '5X ETH CAKE',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'CAKE',
    //   Bytes32: '0x6335455448434841524d00000000000000000000000000000000000000000000',
    //   address: '0xF1fFEdb7ADc7721aA4c44eC0B621D275059ecC9d',
    //   leverage: 5,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '5X Inverse ETH CAKE',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'CAKE',
    //   Bytes32: '0x633549455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x59962148cFB82507D309947775E3aA9f05fDc12C',
    //   leverage: 5,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '10X ETH CAKE',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'CAKE',
    //   Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
    //   address: '0xa54EC9a2fc02706A7E1c346983291B3707a2046d',
    //   leverage: 10,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '10X Inverse ETH CAKE',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'CAKE',
    //   Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xC8271BC72c8C1f1eC850cEE8eD4e3ef70d9D4Eb2',
    //   leverage: 10,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    {
      name: '20X ETH CAKE',
      type: '1',
      tradeType: 'ETH',
      settleType: 'CAKE',
      Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
      address: '0x1e71A83ff14A157fb8816D3C51f5Eb8853b2a47B',
      leverage: 20,
      settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    },
    {
      name: '20X Inverse ETH CAKE',
      type: '0',
      tradeType: 'ETH',
      settleType: 'CAKE',
      Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
      address: '0x415f0f0775ea3CeD281F154DFee8f8c4A95fFb59',
      leverage: 20,
      settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    },
    // {
    //   name: '50X ETH CAKE',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'CAKE',
    //   Bytes32: '0x633530455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x41846f6Ec93CD2EFDdB3Af45513467dc4cA1dD24',
    //   leverage: 50,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '50X Inverse ETH CAKE',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'CAKE',
    //   Bytes32: '0x63353049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xF9F79ac4ebB109aCe8782c11643b0a1FFbE0C576',
    //   leverage: 50,
    //   settleToken: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'
    // },
    // {
    //   name: '5X ETH MATIC',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x6335455448434841524d00000000000000000000000000000000000000000000',
    //   address: '0xF1fFEdb7ADc7721aA4c44eC0B621D275059ecC9d',
    //   leverage: 5,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '5X Inverse ETH MATIC',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633549455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x59962148cFB82507D309947775E3aA9f05fDc12C',
    //   leverage: 5,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '10X ETH MATIC',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
    //   address: '0xa54EC9a2fc02706A7E1c346983291B3707a2046d',
    //   leverage: 10,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '10X Inverse ETH MATIC',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xC8271BC72c8C1f1eC850cEE8eD4e3ef70d9D4Eb2',
    //   leverage: 10,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '20X ETH MATIC',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x1e71A83ff14A157fb8816D3C51f5Eb8853b2a47B',
    //   leverage: 20,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '20X Inverse ETH MATIC',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0x415f0f0775ea3CeD281F154DFee8f8c4A95fFb59',
    //   leverage: 20,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '50X ETH MATIC',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x633530455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x41846f6Ec93CD2EFDdB3Af45513467dc4cA1dD24',
    //   leverage: 50,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '50X Inverse ETH MATIC',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MATIC',
    //   Bytes32: '0x63353049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xF9F79ac4ebB109aCe8782c11643b0a1FFbE0C576',
    //   leverage: 50,
    //   settleToken: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD'
    // },
    // {
    //   name: '5X ETH MDX',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x6335455448434841524d00000000000000000000000000000000000000000000',
    //   address: '0xF1fFEdb7ADc7721aA4c44eC0B621D275059ecC9d',
    //   leverage: 5,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '5X Inverse ETH MDX',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x633549455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x59962148cFB82507D309947775E3aA9f05fDc12C',
    //   leverage: 5,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '10X ETH MDX',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
    //   address: '0xa54EC9a2fc02706A7E1c346983291B3707a2046d',
    //   leverage: 10,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '10X Inverse ETH MDX',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xC8271BC72c8C1f1eC850cEE8eD4e3ef70d9D4Eb2',
    //   leverage: 10,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '20X ETH MDX',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x1e71A83ff14A157fb8816D3C51f5Eb8853b2a47B',
    //   leverage: 20,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '20X Inverse ETH MDX',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0x415f0f0775ea3CeD281F154DFee8f8c4A95fFb59',
    //   leverage: 20,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '50X ETH MDX',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x633530455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x41846f6Ec93CD2EFDdB3Af45513467dc4cA1dD24',
    //   leverage: 50,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '50X Inverse ETH MDX',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'MDX',
    //   Bytes32: '0x63353049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xF9F79ac4ebB109aCe8782c11643b0a1FFbE0C576',
    //   leverage: 50,
    //   settleToken: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739'
    // },
    // {
    //   name: '5X ETH BUSD',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'BUSD',
    //   Bytes32: '0x6335455448425553440000000000000000000000000000000000000000000000',
    //   address: '0x2DA552b5D4666eba599b15bf5128cc912cf995FD',
    //   leverage: 5,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '5X Inverse ETH BUSD',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'BUSD',
    //   Bytes32: '0x6335494554484255534400000000000000000000000000000000000000000000',
    //   address: '0x73E5bBE76633c337B1F55EC8a3995d681bB86899',
    //   leverage: 5,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    {
      name: '10X ETH BUSD',
      type: '1',
      tradeType: 'ETH',
      settleType: 'BUSD',
      Bytes32: '0x633130455448434841524d000000000000000000000000000000000000000000',
      address: '0xa54EC9a2fc02706A7E1c346983291B3707a2046d',
      leverage: 10,
      settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    },
    {
      name: '10X Inverse ETH BUSD',
      type: '0',
      tradeType: 'ETH',
      settleType: 'BUSD',
      Bytes32: '0x63313049455448434841524d0000000000000000000000000000000000000000',
      address: '0xC8271BC72c8C1f1eC850cEE8eD4e3ef70d9D4Eb2',
      leverage: 10,
      settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    },
    // {
    //   name: '20X ETH BUSD',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'BUSD',
    //   Bytes32: '0x633230455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x1e71A83ff14A157fb8816D3C51f5Eb8853b2a47B',
    //   leverage: 20,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '20X Inverse ETH BUSD',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'BUSD',
    //   Bytes32: '0x63323049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0x415f0f0775ea3CeD281F154DFee8f8c4A95fFb59',
    //   leverage: 20,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '50X ETH BUSD',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'BUSD',
    //   Bytes32: '0x633530455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x41846f6Ec93CD2EFDdB3Af45513467dc4cA1dD24',
    //   leverage: 50,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '50X Inverse ETH BUSD',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'BUSD',
    //   Bytes32: '0x63353049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xF9F79ac4ebB109aCe8782c11643b0a1FFbE0C576',
    //   leverage: 50,
    //   settleToken: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
    // },
    // {
    //   name: '50X ETH CHARM',
    //   type: '1',
    //   tradeType: 'ETH',
    //   settleType: 'CHARM',
    //   Bytes32: '0x633530455448434841524d000000000000000000000000000000000000000000',
    //   address: '0x41846f6Ec93CD2EFDdB3Af45513467dc4cA1dD24',
    //   leverage: 50,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },
    // {
    //   name: '50X Inverse ETH CHARM',
    //   type: '0',
    //   tradeType: 'ETH',
    //   settleType: 'CHARM',
    //   Bytes32: '0x63353049455448434841524d0000000000000000000000000000000000000000',
    //   address: '0xF9F79ac4ebB109aCe8782c11643b0a1FFbE0C576',
    //   leverage: 50,
    //   settleToken: '0x706789b6bb7fAC97A57F743dD761642c39954836'
    // },


    //zhh end
  ]
}
export default bscNet;
