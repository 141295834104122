<template>
  <div class="header-box" :class="{ 'ismobile-box': isMobileBol }">
    <div :style="isMobileBol ? 'width: 100%;' : 'max-width: 1400px;'"
      class="header-content d-flex mx-auto align-center justify-space-between">
      <!-- 左侧菜单 -->
      <div class="d-inline-flex align-item">
        <!-- logo -->
        <v-img alt="Logo" class="shrink mr-2" contain src="../assets/logo.png" transition="scale-transition" width="40" />
        <!-- pc -->
        <div class="d-flex align-center" v-if="!isMobileBol">
          <div v-for="(item, index) in menuListLeft" :key="index">
            <div class="pc-meun-item ml-5 mr-5" :class="{ active: currentRouter.path === item.path }"
              @click="handleRouter(item.path)">
              {{ item.label }}
            </div>
          </div>
        </div>
        <!-- mobile -->
        <div v-else @click="handleMenu" style="display: flex; align-item: center">
          <v-img contain src="../assets/menu.png" transition="scale-transition" width="40" />
        </div>
      </div>

      <!-- 右侧操作 -->
      <div class="textColor--text right-top d-flex">

        <div class="types" v-if="!isMobileBol">
          <div v-for="(item, index) in ['Lite','Pro']" :key="index" :class="currentIndex === index ? 'acitve' : ''" @click="handleTypeClick({item,index})">
            {{ item }}
          </div>
        </div>
        <div class="footer_image" v-if="!isMobileBol">
          <div v-for="item in imgList" :key="item.id" @click="handleUrl(item)">
            <img :style="{ 'width': item.width }" :src="item.src" />
          </div>
        </div>


        <div v-if="isLogin && !isMobileBol" class="wallet-detail">
          {{ `${currentEth} ${chainUnit}` }}
        </div>
        <div class="single-box">
          <v-btn v-if="!isLogin" :small="!isMobileBol" :x-small="isMobileBol" @click="handleShowLayer"
            class="mr-1 rounded-lg" :outlined="isMobileBol" color="btnColor"><span class="btnTextColor--text">{{
              $t("解锁钱包") }}</span>
          </v-btn>

          <v-btn v-else :small="!isMobileBol" :x-small="isMobileBol" @click="handleShowLayer"
            class="rounded-lg mobile-right-button" stype="padding:0 8px" :outlined="isMobileBol" color="btnColor"><span
              class="btnTextColor--text">{{
                !currentConnectNetwork
                ? $t("错误网络")
                : $store.state.defaultAccount.substring(0, 3) +
                "..." +
                $store.state.defaultAccount.substring(
                  $store.state.defaultAccount.length - 3,
                  $store.state.defaultAccount.length
                )
              }}</span></v-btn>
        </div>
        <div v-if="isLogin" style="margin-left: 10px">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!isMobileBol" small style="background: #ffffff;border-radius: 15px;color: #081e95;"
                v-bind="attrs" v-on="on">
                {{ connectNetworkTitle && connectNetworkTitle.title }}
              </v-btn>
              <v-btn v-else x-small style="background: #ffffff;border-radius: 15px;color: #081e95;" v-bind="attrs"
                v-on="on">
                {{ connectNetworkTitle && connectNetworkTitle.title }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(item, index) in netWorkItems" :key="index" @click="changeNetWork(item)" link>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-if="pendingStatus" class="pending-status">Pending...</span>
        </div>
        <!-- 切换语言 -->
        <v-select style="width: 96px; margin-left: 20px" :items="languageList" v-model="currentLang"
          @change="changeLanguage" item-text="label" item-value="type" dense dark>
          <template v-slot:selection="{ item }">
            <span class="d-flex justify-left white--text">
              {{ item.label }}
            </span>
          </template>
        </v-select>
      </div>
    </div>

    <!-- mobile菜单弹窗 -->
    <v-dialog v-model="isShowMobileMenu" overlay-color="#333846">
      <div class="mobile-menu-dialog">
        <div>
          <v-btn text v-for="(item, index) in ['Lite','Pro']" :key="index"  :color="currentIndex === index ? 'primary' : ''" @click="handleTypeClick({item,index})">
            {{ item }}
          </v-btn>
        </div>
        <div @click="handleRouter(item.path)" v-for="(item, index) in menuListMobi" :key="index"
          class="text-body-1 text-sm-body-2 font-weight-bold">
          {{ item.label }}
        </div>
      </div>
    </v-dialog>
    <!-- 我的钱包 解锁钱包 -->
    <v-dialog v-model="overlay" :width="!isMobileBol ? '512px' : ''" persistent overlay-color="#333846">
      <!-- 解锁钱包 -->
      <v-card v-if="!isLogin" elevation="2" color="#2e3442" :width="!isMobileBol ? '512px' : '100%'"
        class="pl-5 pr-5 pt-5 pb-5">
        <p class="textColor--text font-weight-bold text-center text-h6">
          {{ $t("请选择钱包类型") }}
        </p>
        <div class="d-flex align-item pt-8 pb-8" style="width: 100%">
          <div class="
              unlock-type-content
              mr-2
              d-inline-flex
              align-center
              flex-column
              pt-3
              pb-3
              pl-3
              pr-3
            ">
            <div class="img-box">
              <img src="../assets/metamask-fox.svg" alt="fox" />
            </div>
            <p class="textColor--text text-subtitle-1 font-weight-bold pt-8 pb-8">
              Metamask
            </p>
            <v-btn @click="handleUnlock" :disabled="!walletInstalled" class="rounded-lg" large color="btnColor"><span
                class="btnTextColor--text">{{
                  walletInstalled ? $t("连接") : $t("请先安装插件")
                }}</span></v-btn>
          </div>
          <div class="
              unlock-type-content
              ml-2
              d-inline-flex
              align-center
              flex-column
              pt-3
              pb-3
              pl-3
              pr-3
            ">
            <div class="img-box">
              <img style="width: 35px" src="../assets/wallet-connect.svg" alt="connect" />
            </div>
            <p class="textColor--text text-subtitle-1 font-weight-bold pt-8 pb-8">
              WalletConnect
            </p>
            <v-btn @click="handleWalletConnect" class="rounded-lg" large color="btnColor"><span
                class="btnTextColor--text">{{ $t("连接") }}</span></v-btn>
          </div>
        </div>
        <v-btn width="100%" class="rounded-lg" large color="btnColor" @click="handleCancel"><span class="textColor--text">
            {{ $t("取消") }}</span>
        </v-btn>
      </v-card>
      <!-- 我的钱包 -->
      <v-card elevation="2" color="#232429" :width="!isMobileBol ? '512px' : '100%'"
        class="pl-5 pr-5 pt-5 pb-5 text-center" v-else>
        <p class="textColor--text font-weight-bold text-center text-h6">
          {{ $t("我的账户") }}
        </p>
        <div style="display: inline-flex; width: 80px; height: 80px">
          <img src="../assets/metamask-fox.svg" alt="fox" />
        </div>
        <p class="textColor--text mt-3 mb-0">
          {{ this.$store.state.defaultAccount }}
        </p>
        <p class="textColor--text mt-3 mb-0">
          {{ currentConnectNetwork ? currentConnectNetwork.name : "" }}
        </p>
        <p class="textColor--text mt-3 mb-0">
          {{ `${currentEth} ${chainUnit}` }}
        </p>
        <v-btn width="100%" class="rounded-lg mt-8 mb-3" large color="btnColor" @click="handleViewOnEarthscan"><span
            class="textColor--text">{{ chainName }}</span>
        </v-btn>
        <v-btn width="100%" class="rounded-lg mb-8" large color="btnColor" @click="handleSignOut"><span
            class="textColor--text"> {{ $t("登出") }}</span>
        </v-btn>
        <v-btn width="100%" class="rounded-lg" large color="btnColor" @click="handleCancel"><span
            class="btnTextColor--text">{{ $t("取消") }}</span>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- 切换网络的 loading效果-->
    <v-overlay :value="changeNetLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import helper from '../helpers'
import chainMap from '../utils/chainsMap.js'// 链ID映射的网络
import eventBus from '../utils/eventBus'
import { mapState, mapMutations } from 'vuex'
import { getNetworkConfig } from '../chainNetConfig.js'

export default {
  name: 'vHeader',
  data() {
    return {
      chainName: '',
      chainUnit: '',
      languageList: [{
        label: '简体中文',
        type: 'zhHans'
      }, {
        label: 'English',
        type: 'en'
      }],
      currentLang: '',
      changeNetLoading: false,
      netWorkItems: [// 切换的网络list
        { title: 'BSC', chainId: 56 },
        { title: 'HECO', chainId: 128 },
        { title: 'Kovan', chainId: 42 }
      ],
      menuListLeft: [ // 左侧菜单
        { path: '/trade', label: this.$t('交易') },
        // { path: '/share', label: this.$t('持仓') },
        { path: '/mineLiquidityDivid', label: this.$t('质押')},


        // { path: '/mine', label: this.$t('质押') },
        // { path: '/liquidity', label: this.$t('流动性') },
        // { path: '/divid', label: this.$t('分红币') },

        { path: '/quotes', label: this.$t('行情') },
        { path: '/satoshi', label: this.$t('聪期权') },
        { path: '/powerswap', label: 'PowerSwap' },
      ],
      menuListRight: [ // 右侧菜单
        // { path: '/powerswap', label: 'PowerSwap' },
        // { path: '/satoshi', label: this.$t('聪期权') },
        // { path: '/tutorial', label: this.$t('操作教程') }
      ],
      imgList: [{
        id: 1,
        src: require('../assets/certik.png'),
        url: 'https://www.certik.org/projects/cppc',
        width: "32px"
      }, {
        id: 2,
        src: require('../assets/TG0820-1.png'),
        url: 'https://titanservice.cn/Charm888',
        width: "32px"
      }, {
        id: 3,
        src: require('../assets/Email0820-1.png'),
        url: 'mailto:info@powerswap.org',
        width: "34px"
      }],
      isLogin: false, // 是否已登录
      isShowMobileMenu: false,
      overlay: false, /// 显示遮罩
      walletInstalled: true,// 是否安装钱包插件
      currentIndex:0 
    }
  },
  async mounted() {
    if (localStorage.getItem('language')) {
      this.currentLang = localStorage.getItem('language')
    } else {
      this.currentLang = 'zhHans'
    }
    this.onResize()
    eventBus.$on('handleUnlockWallet', () => this.handleShowLayer())
    window.addEventListener('resize', this.setRemDebounce(this.onResize, 200), { passive: true })
    this.walletInstalled = helper.IsWalletInstalled()
    if (this.walletInstalled) {
      helper.getAccount((account) => {
        if (account.length > 0) {
          this.handleUnlock()
        }
      })
    }
    this.$nextTick(() => {
      // 中文：CHARM-牛熊证      英文：CHARM-CBBC
      // 中文：CHARM-聪期权      英文：CHARM-SATOSHI
      document.title = this.currentLang === 'zhHans' ? 'CHARM-牛熊证' : 'CHARM-CBBC';
    })

    const config = await getNetworkConfig()
    this.chainUnit = config.unit || ''
    this.chainName = this.$t(config.tradeSuccessTips)
  },
  computed: {
    menuListMobi() { // 移动端 菜单 list
      return [...this.menuListLeft, ...this.menuListRight]
    },
    currentRouter: function () { // 当前路由
      return this.$route
    },
    currentConnectNetwork() { // 当前连接的网络
      const chainId = parseInt(this.defaultChainId, 16) // 链ID 16进制转10进制

      const currentConnect = chainMap.find((item) => { return item.chainId === chainId })// 获取对应的网络
      return currentConnect
    },
    connectNetworkTitle() {
      return this.netWorkItems.find((item) => { return item.chainId === parseInt(this.defaultChainId, 16) })// 获取对应的网络
    },
    ...mapState(['defaultChainId', 'isMobileBol', 'currentEth', 'pendingStatus'])
  },
  watch: {
    '$store.state.defaultAccount': function (val) {
      (async () => {
        await helper.getETHBalance(val, (balance) => {
          const eth = String(balance).replace(/^(.*\..{4}).*$/, '$1')
          this.upCurrentEth(eth)
        })
      })()
    }
  },
  methods: {
    ...mapMutations(['upDateIsMobileBol', 'upDateDefaultAccount', 'upCurrentEth',
      'upDateDefaultChainId', 'upDateIsLogin', 'upCurrentNetworkConfig', 'updateProOrLite']),
    handleUrl(item) {
      window.open(item.url)
    },
    changeLanguage(type) {
      this.changeNetLoading = true
      localStorage.setItem('language', type)
      this.$vuetify.lang.current = type
      window.location.reload()// 待优化，可考虑不用整体刷新
    },
    setRemDebounce(fn, delay) { // 防抖
      let timer = null
      return function () {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(fn, delay)
      }
    },
    onResize() {
      const bol = window.innerWidth < 750
      this.upDateIsMobileBol(bol)
    },
    changeNetWork(item) { // 改变网络
      if (item.chainId === parseInt(this.defaultChainId, 16)) {
        return
      }
      const chainId = '0x' + item.chainId.toString(16)
      window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: chainId }] }).then(() => {
        this.changeNetLoading = true
      }).catch((err) => {
        console.log(err, 'wallet_switchEthereumChain')

        // 如果错误代码( error.code) 是4902，则表示请求的链没有被MetaMask 添加，您必须通过 请求添加它wallet_addEthereumChain。
        if (err.code === 4902) {
          const chain = chainMap.find((i) => { return i.chainId === item.chainId })
          const param = {
            chainId: '0x' + chain.chainId.toString(16), // A 0x-prefixed hexadecimal string
            chainName: chain.name,
            nativeCurrency: chain.nativeCurrency,
            rpcUrls: chain.rpc
            // blockExplorerUrls: chain.explorers,
            // iconUrls: [] // Currently ignored.
          }
          console.log(param)
          window.ethereum.request({ method: 'wallet_addEthereumChain', params: [param] }).then(() => {
            this.changeNetLoading = true
          }).catch((err) => {
            this.changeNetLoading = false
            console.log(err, 'wallet_addEthereumChain')
          })
        }
      })
    },
    /// 显示menu
    handleMenu() {
      this.isShowMobileMenu = true
    },
    /// 点击logo
    handleLogoClick() {

    },

    handleTypeClick(val){
      const { item, index } = val
      this.updateProOrLite(item.toLowerCase())
      this.currentIndex = index
    },
    /// 退出登录
    handleSignOut() {
      this.isLogin = false
      this.overlay = false
      this.upDateDefaultAccount('')
      this.upDateIsLogin(false)
    },
    /// 点击取消
    handleCancel() {
      this.overlay = false
    },
    /// 点击跳转
    handleRouter(path) {
      this.$router.push(path)
      if (this.isMobileBol) {
        this.isShowMobileMenu = false
      }
    },
    /// 解锁钱包 连接pc 端插件
    handleUnlock() {
      console.log('解锁钱包 连接pc 端插件')
      helper.connectWallet((account) => {
        if (account !== '') {
          this.isLogin = true
          this.upDateDefaultAccount(account[0])
          this.upDateIsLogin(true)
        }
      }, async (id) => {
        console.log(id, 'iddddd666')
        if (id !== '') {
          this.upDateDefaultChainId(id)
          const config = await getNetworkConfig()
          this.upCurrentNetworkConfig(config)
        }
      })
      this.overlay = false
    },
    /// 钱包
    handleShowLayer() {
      this.overlay = true
    },
    async handleViewOnEarthscan() {
      const id = this.$store.state.defaultAccount
      const config = await getNetworkConfig()
      const url = `${config.netUrl}/address/${id}`
      window.open(url)
    },
    // 连接app 端钱包
    handleWalletConnect() {
      helper.walletConnect((account) => {
        if (account !== '') {
          this.isLogin = true
          this.upDateDefaultAccount(account[0])
          this.upDateIsLogin(true)
        }
      }, async (id) => {
        if (id !== '') {
          this.upDateDefaultChainId(id)
          const config = await getNetworkConfig()
          this.upCurrentNetworkConfig(config)
        }
      })
      this.overlay = false
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  width: 100%;
  padding: 0 20px;
  height: 76px;

  &.ismobile-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.055);
  }

  .header-content {
    height: 100%;
    margin: 0 auto;

    .pc-meun-item {
      position: relative;
      font-size: 18px;
      font-weight: 800;
      cursor: pointer;
      color: #fff;

      &:hover {
        color: #bb4dba;
      }

      &.active {
        color: #bb4dba;
        // border-bottom: 2px solid rgb(255, 255, 255);
      }

      // 动画
      &:before {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -2px;
        width: 0;
        height: 2px;
        background: #bb4dba;
        transition: all 0.2s;
      }

      &:hover:before {
        width: 100%;
        left: 0;
        right: 0;
      }
    }
  }

  .right-top {
    display: flex;
    align-items: center;

    .wallet-detail {
      height: 24px;
      line-height: 25px;
      font-weight: bold;
    }

    .single-box {
      text-align: right;
    }

    .mobile-right-button {
      margin-left: 3px;
      padding: 0 8px !important;
    }

    .right-menu {
      display: flex;
      align-items: center;

      >div {
        font-size: 14px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: left;
        cursor: pointer;
        color: #ffffff;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .pending-status {
    font-size: 12px;
    padding: 7px;
    margin: 0 2px;
    text-align: center;
    background: rgb(25 118 210);
    color: #fff;
    border-radius: 4px;
    animation: glow 800ms ease-out infinite alternate;
    bottom: 0px;
  }

  @keyframes glow {
    0% {
      opacity: 0.45;
    }

    100% {
      opacity: 1;
    }
  }
}

.mobile-menu-dialog {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: auto;

  >div {
    width: 100%;
    height: 45px;
    line-height: 45px;
    text-align: center;
  }
}

.unlock-type-content {
  border: 1px solid rgb(59 66 78);
  border-radius: 12px;
  box-shadow: rgb(59 66 78) 0px 0px 6px;
  background: #2e3442;
  width: 50%;

  .img-box {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
  }
}

.footer_image {
  display: flex;
  align-items: baseline;

  >div {
    margin-right: 12px;

    img {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.types {
  display: flex;
  background-color: #132848;
  margin-right: 20px;
  color: #ccc;
  .acitve {
    background-color: #1A67DE;
    color: #ffffff;
  }
  div {
    min-width: 55px;
    text-align: center;
    padding: 3px 0;
    cursor: pointer;
    border-radius: 6px;
  }
}

</style>
